import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'

const TooltipStyled = styled(({
  className,
  ...rest
}) => (
  <Tooltip
    {...rest}
    classes={{ popper: className }}
  />
), {
  name: 'UiTooltip',
  overridesResolver: (_props, styles) => [
    styles.root,
  ]
})(() => {})

export {
  TooltipStyled
}
