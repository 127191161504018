import { useNavigate } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import Typography from '../../components/Typography';
import ButtonField from '../../components/Button';
import {
  ModalEmail,
  BoxEmail,
  ModalEmailHeader,
  ModalEmailInnerContainer,
  ModalEmailItem,
  ModalMessage
} from './EmailModal.style';
import { PaymentPageLogo } from '../payment-layout/PaymentLayout.style';
import emailAvatar from '../../assets/email-avatar.svg'
import closeEmail from '../../assets/close-email.svg'
import cryptoLogo from '../../assets/crypto-logo.svg';

export const EmailModal = ({emailModalIsOpen, closeModalEmail, isUnderpayment}) => {
  const navigate = useNavigate();
    return (
        <ModalEmail 
        open={emailModalIsOpen}
        onClose={closeModalEmail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ 
          "& > .MuiBackdrop-root" : {
                background: 'rgba(4, 5, 23, 0.56)',
                }
          }}
        >
        <BoxEmail>
          <ModalEmailHeader>
            <img src={emailAvatar} alt='logo' />
            <div style={{marginLeft: 12}}>
            <Typography style={{marginBottom: 4}} variant='body1' color='#040517'>CoinsPaid</Typography>
            <Typography variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>To: user@mail.com</Typography>
            </div>
            <img onClick={closeModalEmail} style={{marginLeft: 'auto', alignSelf: 'flex-start'}} src={closeEmail} alt='logo' />
          </ModalEmailHeader>
          <ModalEmailInnerContainer>
            <PaymentPageLogo style={{marginBottom: 24, width: 158.4, height: 48}} src={cryptoLogo} alt='logo' />
            <Typography style={{marginBottom: 8}} variant='h1' color='#040517'>Refund</Typography>
            <Typography style={{marginBottom: 24, display: 'inline-block'}} variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>{isUnderpayment ? 'Funds will be returned due to the insufficient payment amount': 'Funds will be returned due to excess payment'}</Typography>
            <ModalEmailItem>
              <Typography variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Refund ID</Typography>
              <Typography variant='subtitle3' color='#040517'>856324</Typography>
            </ModalEmailItem>
            <ModalEmailItem>
              <Typography variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Invoice ID</Typography>
              <Typography variant='subtitle3' color='#040517'>2156324</Typography>
            </ModalEmailItem>
            <ModalEmailItem>
              <Typography variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Invoice</Typography>
              <Typography variant='subtitle3' color='#040517'>Certificate #191</Typography>
            </ModalEmailItem>
            <ModalEmailItem>
              <Typography variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Merchant</Typography>
              <Typography variant='subtitle3' color='#040517'>JumpSky</Typography>
            </ModalEmailItem>
            <ModalEmailItem>
              <Typography variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Refundable amount</Typography>
              <Typography variant='subtitle3' color='#040517'>{isUnderpayment ? '1207.53 USDT' : '150 USDT'}</Typography>
            </ModalEmailItem>
            <ModalEmailItem style={{borderBottom: 0, paddingBottom: 0}}>
              <Typography variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Reason</Typography>
              <Typography style={{maxWidth: 180, textAlign: 'right'}} variant='subtitle3' color='#040517'>{isUnderpayment ? 'Your payment is less than the required total' : 'Your payment exceeds the required total'}</Typography>
            </ModalEmailItem>
            <ModalMessage>
              <Typography style={{textAlign: 'center'}} variant='caption3' color='#040517'>A refund processing fee will be applied to the refundable amount</Typography>
            </ModalMessage>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <ButtonField onClick={() => navigate('/refund', {state: {from: isUnderpayment ? '/payment-underpaid' : '/payment-overpaid'} })} sx={{width: 320, "@media (max-width: 450px)": {width: '100%'}}} size='large'>Get refund</ButtonField>
            </div>
          </ModalEmailInnerContainer>
        </BoxEmail>
        </ModalEmail>
    )
}
