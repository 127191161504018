export default {
  mode: 'light',
  primary: {
    main: 'rgb(20, 75, 255)',
  },
  secondary: {
    main: 'rgba(4, 5, 23, 1)',
  },
  error: {
    main: 'rgb(255, 71, 84)',
  },
  warning: {
    main: 'rgb(255, 153, 10)',
  },
  success: {
    main: 'rgb(19, 194, 112)',
  },
  info: {
    main: 'rgb(20, 75, 255)',
  },
  background: {
    default: 'rgb(250, 250, 251)', //body
    paper: 'rgb(255, 255, 255)', //layout
    above: 'rgba(255, 255, 255, 1)',
    menu: 'rgb(4, 5, 23)',
    tint: 'rgba(4, 5, 23, 0.68)',
  },
  surface: {
    accent: 'rgba(4, 5, 23, 1)',
  },
  default: {
    main: '#fff', //Biutton color="white"
  },
  static: {
    900: 'rgba(255, 255, 255, 1)',
    800: 'rgba(255, 255, 255, 0.64)',
    700: 'rgba(255, 255, 255, 0.48)',
    600: 'rgba(255, 255, 255, 0.4)',
    500: 'rgba(255, 255, 255, 0.32)',
    400: 'rgba(255, 255, 255, 0.24)',
    300: 'rgba(255, 255, 255, 0.16)',
    200: 'rgba(255, 255, 255, 0.08)',
    100: 'rgba(255, 255, 255, 0.04)',
  },
  basic: {
    900: 'rgba(4, 5, 23, 1)',
    800: 'rgba(4, 5, 23, 0.68)',
    700: 'rgba(4, 5, 23, 0.56)',
    600: 'rgba(4, 5, 23, 0.40)',
    500: 'rgba(4, 5, 23, 0.32)',
    400: 'rgba(4, 5, 23, 0.24)',
    300: 'rgba(4, 5, 23, 0.16)',
    200: 'rgba(4, 5, 23, 0.08)',
    100: 'rgba(4, 5, 23, 0.04)',
    A100: 'rgba(255, 255, 255, 0.04)',
    A200: 'rgba(255, 255, 255, 0.08)',
    A300: 'rgba(255, 255, 255, 0.16)',
    A400: 'rgba(255, 255, 255, 0.24)',
    A500: 'rgba(255, 255, 255, 0.32)',
    A600: 'rgba(255, 255, 255, 0.40)',
    A700: 'rgba(255, 255, 255, 0.48)',
    A800: 'rgba(255, 255, 255, 0.64)',
    A900: 'rgba(255, 255, 255, 100)',
  },
  shadows: {
    regular: '0px 7px 20px rgba(0, 0, 0, 0.07)',
    large: '0px 7px 64px rgba(0, 0, 0, 0.07)',
    hover: '0px 8px 10px rgba(16, 20, 23, 0.04), 0px 8px 16px rgba(16, 20, 23, 0.16)',
    standart:
      '0px 0px 2px rgba(16, 20, 23, 0.04), 0px 1px 1px rgba(16, 20, 23, 0.02), 0px 1px 4px rgba(16, 20, 23, 0.08)',
  },
  action: {
    disabledOpacity: 0.32,
    focusOpacity: 0.1,
  },
}
