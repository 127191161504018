import styled from '@emotion/styled'
import { Container} from '@mui/material';

export const PaymentPage = styled('div')({
    backgroundColor: '#FAFAFB',
    minHeight: 'calc(100vh - 48px)',
    height: '100%',
    padding: '0 48px 48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    "@media (max-width: 450px)": {
        padding: 0,
        backgroundColor: '#FFF',
    }
})

export const PaymentPageLogoContainer = styled('div')({
    padding: '48px 0',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'start',
    justifySelf: 'start',
    "@media (max-width: 450px)": {
        display: 'none'
    }
})

export const PaymentPageLogo = styled('img')({
    width: 132,
    height: 40,
    cursor: 'pointer',
    "@media (max-width: 450px)": {
    }
})

export const PaymentContainerGeneral = styled(Container)({
    width: '100%',
    height: '100%',
    maxWidth: '536px!important',
    backgroundColor: 'white',
    borderRadius: '16px',
    boxShadow: '0px 1px 4px 0px rgba(16, 20, 23, 0.08), 0px 1px 1px 0px rgba(16, 20, 23, 0.02), 0px 0px 2px 0px rgba(16, 20, 23, 0.04)',
    paddingLeft: '0!important',
    paddingRight: '0!important',
    "@media (max-width: 450px)": {
        boxShadow: 'none',
        borderRadius: 0,
        maxWidth: '100%'
    }
})

export const PaymentHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '18px 40px',
    borderBottom: '1px solid rgba(4, 5, 23, 0.08)',
    "@media (max-width: 450px)": {
        padding: '18px 24px',
    }
})

export const PaymentBrandLogoContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    "@media (max-width: 450px)": {
    }
})

export const PaymentBrandLogo = styled('img')({
    width: 20,
    height: 20,
    marginRight: 8,
    "@media (max-width: 450px)": {
    }
})

export const PaymentLang = styled('img')({
    
    "@media (max-width: 450px)": {
    }
})

export const PaymentContainerInner = styled('div')({
    padding: '24px 40px',
    display: 'flex',
    flexDirection: 'column',
    "@media (max-width: 450px)": {
        padding: '24px',
    }
})
