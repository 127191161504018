import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@mui/material'

/**
 * Returns MUI Checkbox. Checkboxes allow the user to select one or more items from a set.
 * @param props
 *
 * @param {string} [props.className] -  Override or extend the styles applied to the component
 *
 * @param {string} [props.id] -  The id of the input element.
 *
 * @param {boolean} props.checked -  If true, the component is checked.
 *
 * @param {ReactElement} [props.checkedIcon=CheckBoxIcon] - The icon to display when the component is checked.
 *
 * @param {string} [props.color=secondary] -  The color of the component.
 * It supports those theme colors that make sense for this component.
 *
 * @param {boolean} props.disabled -  If true, the checkbox will be disabled.
 *
 * @param {boolean} props.disableRipple -  If true, the ripple effect will be disabled.
 *
 * @param {ReactElement} [props.icon=CheckBoxOutlineBlankIcon] -  The icon to display when the component is unchecked.
 *
 * @param {boolean} [props.indeterminate=false] -  If true, the component appears indeterminate.
 * This does not set the native input element to indeterminate due to inconsistent behavior across browsers.
 * However, we set a data-indeterminate attribute on the input.
 *
 * @param {ReactElement} [props.indeterminateIcon=IndeterminateCheckBoxIcon] -  The icon to display when the
 * component is indeterminate.
 *
 * @param {object} props.inputProps -  Attributes applied to the input element.
 *
 * @param {ref} props.inputRef -  Attributes applied to the input element.
 *
 * @param {function} props.onChange -  Callback fired when the state is changed.
 * Signature: function(event: object) => void event: The event source of the callback.
 * You can pull out the new checked state by accessing event.target.checked (boolean).
 *
 * @param {boolean} props.required -  If true, the input element will be required.
 *
 * @param {string} [props.size=medium] -  The size of the checkbox. small is equivalent to the dense checkbox styling.
 *
 * @param {any} props.value -  The value of the component.
 * The DOM API casts this to a string. The browser uses "on" as the default value.
 */
const CheckboxComponent = (props) => {
  const {
    checked,
    checkedIcon,
    className,
    color,
    disabled,
    disableRipple,
    icon,
    id,
    indeterminate,
    indeterminateIcon,
    inputProps,
    inputRef,
    onChange,
    required,
    size,
    value,
    dataTestId,
    sx,
  } = props

  return (
    <Checkbox
      checked={checked}
      checkedIcon={checkedIcon}
      className={className}
      color={color}
      disabled={disabled}
      disableRipple={disableRipple}
      icon={icon}
      id={id}
      indeterminate={indeterminate}
      indeterminateIcon={indeterminateIcon}
      inputProps={inputProps}
      inputRef={inputRef}
      required={required}
      size={size}
      value={value}
      onChange={onChange}
      data-testid={`checkbox-${dataTestId}`}
      sx={sx}
    />
  )
}

CheckboxComponent.propTypes = {
  /**
   * Custom classname.
   */
  className: PropTypes.string,
  /**
   * The id of the input element.
   */
  id: PropTypes.string,
  /**
   * If true, the component is checked.
   */
  checked: PropTypes.bool,
  /**
   * The icon to display when the component is checked.
   */
  checkedIcon: PropTypes.element,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'warning',
    'error',
    'info',
    'default',
  ]),
  /**
   * If true, the checkbox will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If true, the ripple effect will be disabled.
   */
  disableRipple: PropTypes.bool,
  /**
   * The icon to display when the component is unchecked.
   */
  icon: PropTypes.element,
  /**
   * If true, the component appears indeterminate. This does not set the native input element
   * to indeterminate due to inconsistent behavior across browsers.
   * However, we set a data-indeterminate attribute on the input.
   */
  indeterminate: PropTypes.bool,
  /**
   * The icon to display when the component is indeterminate.
   */
  indeterminateIcon: PropTypes.node,
  /**
   * Attributes applied to the input element.
   */
  inputProps: PropTypes.object,
  /**
   * Attributes applied to the input element.
   */
  inputRef: PropTypes.object,
  /**
   * Callback fired when the state is changed.
   * Signature: function(event: object) => void event: The event source of the callback.
   * You can pull out the new checked state by accessing event.target.checked (boolean).
   */
  onChange: PropTypes.func,
  /**
   * If true, the input element will be required.
   */
  required: PropTypes.bool,
  /**
   * The size of the checkbox. small is equivalent to the dense checkbox styling.
   */
  size: PropTypes.oneOf(['medium', 'small']),
  /**
   * The value of the component. The DOM API casts this to a string. The browser uses "on" as the default value.
   */
  value: PropTypes.any,
}

CheckboxComponent.defaultProps = {
  indeterminate: false,
  color: 'primary',
  disableRipple: true,
}

export default CheckboxComponent
