import { pxToRem } from '../../../../utils/pxToRem'

export const UiTextField = {
  styleOverrides: {
    input: ({ theme: { palette } }) => ({
      fontSize: pxToRem(14),
      fontWeight: 400,
      ...(palette.mode === 'light' ? { background: palette.basic['A900'], color: palette.basic['900'] } : {}),
      '& .MuiOutlinedInput-input': {
        padding: `${pxToRem(18)} ${pxToRem(16)}`,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.basic[200],
        '& legend': {
          fontSize: pxToRem(10),
        },
      },
      '&:hover:not(.Mui-disabled):not(.Mui-error):not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.basic[300],
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${palette.primary.main}`,
      },
      '&.MuiInputBase-adornedEnd': {
        paddingRight: pxToRem(16),
        '& .MuiOutlinedInput-input': {
          paddingRight: 0,
        },
      },
      '&.MuiInputBase-adornedStart': {
        paddingLeft: pxToRem(16),
        '& .MuiOutlinedInput-input': {
          paddingLeft: 0,
        },
      },
      /**
       * Hide Safari contacts auto-fill
       * https://zdevteam.atlassian.net/browse/CP-4246
       *
       */
      '& input.MuiInputBase-input::-webkit-contacts-auto-fill-button': {
        visibility: 'hidden',
        display: 'none !important',
        pointerEvents: 'none',
        height: 0,
        width: 0,
        margin: 0,
      },
    }),

    inputReadOnly: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderStyle: 'dashed',
      },
    },

    inputDisabled: ({ theme: { palette } }) => ({
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px dashed ${palette.basic[300]}`,
      },
      '& .MuiOutlinedInput-input': {
        WebkitTextFillColor: palette.basic[400],
      },
    }),

    defaultIcon: ({ theme: { palette } }) => ({
      cursor: 'pointer',
      '& svg path': {
        fill: palette.basic[400],
      },
    }),

    inputAdornmentDisabled: {
      opacity: 0.24,
    },

    label: ({ theme: { palette } }) => ({
      color: palette.basic[700],
      fontWeight: 400,
      transform: `translate(${pxToRem(14)}, ${pxToRem(18)}) scale(1)`,
      '&.Mui-disabled': {
        color: palette.basic[400],
      },
      '&.Mui-error:not(.MuiInputLabel-shrink)': {
        color: palette.basic[700],
      },
      '&.Mui-focused, &.MuiInputLabel-shrink': {
        transform: `translate(${pxToRem(14)}, ${pxToRem(-7)}) scale(0.7)`,
      },
    }),

    helperText: ({ theme: { palette } }) => ({
      color: palette.basic[700],
      fontWeight: 400,
    }),
  },
}

const UiTextFieldIcon = {
  variants: [{
    props: { isError: true },
    style: ({ theme: { palette } }) => ({
      '& svg path': {
        fill: palette.error.main,
      },
    })
  }],

  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      cursor: 'pointer',
      '& svg path': {
        fill: palette.basic[400],
      },
    }),

    /* fieldIcon: ({ theme: { palette } }) => ({
      '& svg path': {
        fill: palette.basic[400],
      },
    }),*/
  },
}

export {
  UiTextFieldIcon
}
