import { makeStyles } from '@mui/styles'

const FONT_FAMILY = 'Inter, sans-serif'

export const styles = makeStyles(({ palette: { mode, ...palette }, spacing, typography: { pxToRem } }) => ({
  currencyContainer: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    height: pxToRem(24),
    width: pxToRem(24),
    marginRight: spacing(1),
  },
  label: {
    zIndex: 1,
    background: 'transparent',
    color: palette.action.active,
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: pxToRem(22),
    paddingLeft: pxToRem(5),
    paddingRight: pxToRem(5),
    position: 'absolute',
    left: spacing(2),
    top: spacing(2),
    display: 'none',
    transition: 'background 100ms, visibility 50ms, top 150ms, left 150ms',
  },
  labelOnTop: {
    left: 0,
    top: spacing(-1.5),
    display: 'block',
    background: palette.background.paper,
    transform: 'scale(0.75)',
  },
  labelFocus: {
    color: palette.basic['900'],
    transform: 'scale(0.75)',
  },
  control: {
    position: 'relative',
  },
  optionContainer: {
    padding: '8px',
    fontFamily: FONT_FAMILY,
    fontSize: '14px',
    color: palette.basic['900'],
    lineHeight: '24px',
  },
  checkbox: {
    color: palette.basic[100],
    '&.Mui-checked': {
      color: palette.primary.main,
    },
  },
  iso: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtext: {
    width: 'inherit',
    color: palette.basic[700],
  },
}))

const getSelectOptionStyles = ({ palette }, state) => {
  switch (true) {
    case state.isSelected:
      return {
        color: palette.basic['900'],
        background: palette.basic[100],
      }
    case state.isFocused:
      return {
        background: palette.basic[100],
      }
    default:
      return {}
  }
}

// eslint-disable-next-line max-lines-per-function
export const selectStyles = ({ palette: { mode, ...palette }, typography: { pxToRem } }, isAdornment) => ({
  control: (style, state) => {
    let borderColor = state.selectProps.error ? palette.error.main : palette.basic[300]

    if (state.menuIsOpen) {
      borderColor = palette.primary.main
    }

    return {
      ...style,
      ...(mode === 'light'
        ? {}
        : {
          backgroundColor: 'transition',
        }),
      minHeight: '54px',
      height: 'auto',
      borderColor,
      borderStyle: state.isDisabled ? 'dashed' : 'solid',
      borderWidth: '1px',
      boxShadow: 'none',
      fontFamily: FONT_FAMILY,
      ...(state.menuIsOpen
        ? {
          borderRadius: '8px 8px 0 0',
        }
        : { borderRadius: '8px' }),
      ...(state.isDisabled ? { backgroundColor: 'transparent' } : {}),
      '&:hover': {
        borderColor: palette.primary.main,
      },
      '&:active': {
        borderColor: palette.primary.main,
      },
    }
  },
  input: (style) => ({
    ...style,
    ...(mode === 'light'
      ? {}
      : {
        color: palette.basic['900'],
        fontWeight: 400,
        fontSize: pxToRem(14),
      }),
  }),
  placeholder: (style) => ({
    ...style,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: palette.basic[800],
    opacity: 0.9,
    margin: 0,
  }),
  valueContainer: (style) => ({
    ...style,
    fontWeight: 400,
    ...(isAdornment ? { padding: '10px 0px' } : { padding: '10px 14px' }),
  }),
  singleValue: (style) => ({
    ...style,
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: 0,
    color: palette.basic['900'],
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (style, state) => ({
    ...style,
    color: palette.primary[state.isDisabled ? 300 : 400],
    '&:hover': {
      color: palette.basic['900'],
    },
  }),
  menu: (style, state) => ({
    ...style,
    borderRadius: '0 0 8px 8px',
    boxShadow: 'none',
    border: `1px solid ${state.selectProps.error ? palette.error.main : palette.basic[100]}`,
    borderTopWidth: 0,
    margin: 0,
    zIndex: 10000,
    backgroundColor: palette.basic.A900,
  }),
  menuList: (style) => ({
    ...style,
    padding: 0,
    '::-webkit-scrollbar-thumb': {
      background: palette.basic['200'],
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: palette.basic['300'],
    },
  }),
  menuPortal: (style) => ({
    ...style,
    zIndex: 10000,
  }),
  option: (style, state) => ({
    ...style,
    ...(mode === 'light'
      ? {}
      : {
        backgroundColor: 'transition',
      }),
    padding: '13px 14px',
    fontFamily: 'Inter, sans-serif',
    cursor: 'pointer',
    ...(state.data.isClearable
      ? {
        fontSize: '10px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: palette.basic[500],
      }
      : {
        fontSize: '14px',
        color: palette.basic['900'],
      }),
    lineHeight: '20px',
    fontWeight: 400,
    '&:active': {
      backgroundColor: palette.basic[100],
    },
    ...(state.options[state.options.length - 1].value === state.value
      ? {
        borderRadius: '0 0 8px 8px',
      }
      : { borderRadius: 0 }),

    ...getSelectOptionStyles({ palette }, state),
  }),
  multiValueRemove: (base, state) => (state.data.isFixed ? { ...base, display: 'none' } : base),
  multiValueLabel: (base, state) => (state.data.isFixed ? { ...base, paddingRight: 6 } : base),
  noOptionsMessage: (style) => ({
    ...style,
    fontFamily: FONT_FAMILY,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  }),
  loadingMessage: (style) => ({
    ...style,
    fontFamily: FONT_FAMILY,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  }),
})
