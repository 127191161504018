/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { useTheme } from '@mui/material/styles'
import {
  FormControl,
  FormHelperText,
  Typography,
  Checkbox,
  Grid,
} from '@mui/material'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import _isNil from 'lodash/isNil'
import _isEmpty from 'lodash/isEmpty'
import _isObjectLike from 'lodash/isObjectLike'
import clsx from 'clsx'

import { styles, selectStyles } from './styles'

const Template = (props, state) => {
  const theme = useTheme()
  const classes = styles()
  const selectClasses = selectStyles(theme, props.isAdornment)

  const MultiValueRemove = (componentProps) => (props.multiValueRemoveComponent ? (
    props.multiValueRemoveComponent(componentProps)
  ) : (
    <components.MultiValueRemove {...componentProps} />
  ))

  const Option = ({ innerProps, isSelected, children }) => (
    <Grid
      {...innerProps}
      container
      alignItems='center'
      className={classes.optionContainer}
    >
      <Checkbox
        checked={isSelected}
        color='default'
        className={classes.checkbox}
      />
      {children}
    </Grid>
  )

  return (
    <FormControl
      data-testid={`select-${props.dataTestId}`}
      className={clsx(props.className, classes.control)}
      error={!!props.error}
      fullWidth={props.fullWidth}
      sx={props.sx}
    >
      <Typography
        className={clsx(classes.label, {
          [classes.labelOnTop]:
            state.isFocus
            || (_isObjectLike(props.value) ? !_isEmpty(props.value) : !_isNil(props.value) && props.value !== ''),
          [classes.labelFocus]: state.isFocus,
        })}
      >
        {props.label}
      </Typography>
      <AsyncSelect
        noOptionsMessage={props.noOptionsMessage}
        loadOptions={props.loadOptions}
        placeholder={state.isFocus ? props.placeholder : props.label}
        label={props.label}
        maxMenuHeight={props.maxMenuHeight || 192}
        error={!!props.error || props.hasError}
        value={props.value ?? null}
        onChange={props.onChange}
        onMenuOpen={state.onMenuOpen}
        onMenuClose={state.onMenuClose}
        autoWidth
        styles={selectClasses}
        components={{
          MultiValueRemove,
          ...(props.isMulti ? { Option } : {}),
          ...(props.customOption ? { Option: props.customOption } : {}),
        }}
        isDisabled={props.disabled}
        isClearable={props.isClearable}
        isMulti={props.isMulti}
        defaultOptions={props.defaultOptions}
        hideSelectedOptions={false}
        closeMenuOnSelect={!props.isMulti}
        defaultMenuIsOpen={props.defaultMenuIsOpen}
      />
      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  )
}

export default Template
