import styled from '@emotion/styled'
import { Modal, Box } from '@mui/material';

export const ModalDeposit = styled(Modal, {
  shouldForwardProp: (prop) => prop !== "isSuccessPayment"
})((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems:"center",
  "@media (max-width: 450px)": {
    top: 'unset'
  }
}));

export const BoxDeposit = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isSuccessPayment"
  })((props) => ({
  display: props.isSuccessPayment ? 'block' : 'grid',
  gridTemplateAreas:
    `"top top"
    "left right"`,
  backgroundColor: 'white',
  gridTemplateRows: '57px 1fr',
  gridTemplateColumns: '1fr 1fr',
  padding: 0,
  margin: 0,
  maxWidth: props.isSuccessPayment ? 536 : 700,
  maxHeight: props.isSuccessPayment? 'auto' : 644,
  width: '100%',
  height: props.isSuccessPayment? 'auto' : '100%',
  borderRadius: 16,
  border: 'none',
  boxShadow: '0px 1px 4px 0px rgba(16, 20, 23, 0.08), 0px 1px 1px 0px rgba(16, 20, 23, 0.02), 0px 0px 2px 0px rgba(16, 20, 23, 0.04)',
  "@media (max-width: 450px)": {
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    gridTemplateAreas:
    `"top"
    "left" 
    "right"`,
    gridTemplateRows: '57px auto 1fr',
    gridTemplateColumns: 'none',
    maxHeight: '100%',
    overflow: 'auto'
  },
}));

export const HeadingDeposit = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems:"center",
  borderBottom: '1px solid #EBEBEC',
  padding: '0 20px',
  height: 56,
  gridArea: 'top',
  "@media (max-width: 450px)": {
    padding: '0 24px',
  }
});

export const SuccessModalContainer = styled('div')({
  padding: '16px 40px 0 40px',
  display: 'flex',
  flexDirection: 'column',
  "@media (max-width: 450px)": {
    padding: '16px 24px 0 24px',
  }
});

export const ColumnDeposit = styled('div', {
  shouldForwardProp: (prop) => prop !== "secondary"
})((props) => ({
  backgroundColor: props.secondary ? '#E9F3FC' : 'white',
  gridArea: props.secondary ? 'left' : 'right',
  borderBottomLeftRadius: props.secondary ? 16 : 0,
  borderBottomRightRadius: props.secondary ? 0 : 16,
  padding: '40px',
  "@media (max-width: 450px)": {
    padding: '24px',
    paddingRight: props.secondary ? 0 : 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: 'hidden'
    },
}));

export const ColumnDepositSubject = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px',
  alignSelf: 'stretch',
  position: 'relative',
  margin: '24px 0',
  "@media (max-width: 450px)": {
    // width: '100%'
  }
});

export const CaptionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 24,
  "@media (max-width: 450px)": {
    flexDirection: 'row',
    overflowX: 'scroll',
    width: '100%'
  },
});

export const CaptionItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== "selected"
})((props) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '12px',
  border: `${props.selected ? ' 2px solid #FEDD67' : '1px solid rgba(4, 5, 23, 0.08)'}`,
  background: '#FFF',
  padding: '14px 16px',
  marginBottom: 8,
  "@media (max-width: 450px)": {
      width: '250px',
      flexShrink: 0,
      marginBottom: 0,
      marginRight: 8,
    },
}));

export const CaptionItemRadio = styled('div') ({
  borderRadius: '100%',
  width: 16,
  height: 16,
  border: '1px solid rgba(4, 5, 23, 0.08)',
  background: '#FFF',
  marginLeft: 'auto',
  "@media (max-width: 450px)": {
      
    },
});
