import styled from '@emotion/styled'

export const MenuContainer = styled('div')({
    position: 'fixed',
    right: 48,
    bottom: 48,
    "@media (max-width: 450px)": {
      right: 24, 
      bottom: 24
    }
})
