export const MuiInput = {
  styleOverrides: {
    input: ({ theme: { palette } }) => ({
      '&::placeholder': {
        color: palette.basic[800],
      },
    }),
    underline: ({ theme: { palette } }) => ({
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `2px solid ${palette.primary.main}`,
      },
      '&$focused:before': {
        borderBottom: `2px solid ${palette.primary.main}`,
      },
      '&:before': {
        borderBottom: `2px solid ${palette.basic[300]}`,
      },
      '&:after': {
        borderBottom: `2px solid ${palette.primary.main}`,
      },
    }),
  },
}
