import {useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import {Menu as MenuMUI, MenuItem, IconButton}  from "@mui/material";
import Typography from '../../components/Typography';
import Tooltip from '../../components/Tooltip';
import tick from '../../assets/icon-tick.svg';
import { MenuContainer } from './Menu.style';

const IconMenu = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.7837 5H19.25C19.6642 5 20 5.33579 20 5.75C20 6.16421 19.6642 6.5 19.25 6.5H17.8933C17.6359 7.22825 16.9414 7.75 16.125 7.75C15.3086 7.75 14.6141 7.22825 14.3567 6.5H4.75C4.33579 6.5 4 6.16421 4 5.75C4 5.33579 4.33579 5 4.75 5H14.4663C14.7806 4.40527 15.4055 4 16.125 4C16.8445 4 17.4694 4.40527 17.7837 5ZM4.75 11C4.33579 11 4 11.3358 4 11.75C4 12.1642 4.33579 12.5 4.75 12.5H6.21626C6.53063 13.0947 7.15549 13.5 7.875 13.5C8.59451 13.5 9.21936 13.0947 9.53374 12.5H19.25C19.6642 12.5 20 12.1642 20 11.75C20 11.3358 19.6642 11 19.25 11H9.64331C9.38591 10.2718 8.69139 9.75 7.875 9.75C7.05861 9.75 6.36409 10.2718 6.10669 11H4.75ZM4 18.25C4 17.8358 4.33579 17.5 4.75 17.5H14.3567C14.6141 16.7718 15.3086 16.25 16.125 16.25C16.9414 16.25 17.6359 16.7718 17.8933 17.5H19.25C19.6642 17.5 20 17.8358 20 18.25C20 18.6642 19.6642 19 19.25 19H17.7837C17.4694 19.5947 16.8445 20 16.125 20C15.4055 20 14.7806 19.5947 14.4663 19H4.75C4.33579 19 4 18.6642 4 18.25Z" fill="white"/>
    </svg>
  )
}

const IconArrow = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 9.25L12 16.75L4.5 9.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

const IconClose = ({onClick}) => <svg style={{ cursor: 'pointer'}} onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M2.55806 2.55806C2.80214 2.31398 3.19786 2.31398 3.44194 2.55806L8 7.11612L12.5581 2.55806C12.8021 2.31398 13.1979 2.31398 13.4419 2.55806C13.686 2.80214 13.686 3.19786 13.4419 3.44194L8.88388 8L13.4419 12.5581C13.686 12.8021 13.686 13.1979 13.4419 13.4419C13.1979 13.686 12.8021 13.686 12.5581 13.4419L8 8.88388L3.44194 13.4419C3.19786 13.686 2.80214 13.686 2.55806 13.4419C2.31398 13.1979 2.31398 12.8021 2.55806 12.5581L7.11612 8L2.55806 3.44194C2.31398 3.19786 2.31398 2.80214 2.55806 2.55806Z" fill="white"/>
</svg>

export const Menu  = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipIsOpen, toggleTooltip] = useState(true)
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, route) => {
    if(route && route !== 'backdropClick') navigate(route);
    setAnchorEl(null);
  };

  return (
    <MenuContainer>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => handleClick(e, null)}
        style={{width: 56, height: 56, backgroundColor: 'rgba(11, 11, 15, 1)', borderRadius: 16,}}
      >
        {open ?<IconArrow /> :<IconMenu />}
      </IconButton>
      <Tooltip icon={<IconClose onClick={() => toggleTooltip(false)}/>}
        isMenu={true}
        text='Limited functionality
        For preview purposes only'
        placement='left-start'
        open={tooltipIsOpen}
        title='Demo mode'
        actionButtonText='Sure'
        arrow={true}
        onActionButtonClick={() => toggleTooltip(false)}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [20, 50],
                },
              },
            ],
          },
        }}
      >
      </Tooltip>
      <MenuMUI
      style={{boxShadow: '0px 1px 4px 0px rgba(16, 20, 23, 0.08), 0px 1px 1px 0px rgba(16, 20, 23, 0.02), 0px 0px 2px 0px rgba(16, 20, 23, 0.04)'}}
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        slotProps={{
          paper: {
          style: {
            maxHeight: '100%'
          },
        }
        }}
      >
        <MenuItem style={{pointerEvents: 'none'}}>
        <Typography variant='overline' color='rgba(4, 5, 23, 0.56)'>
          STATES
        </Typography>
        </MenuItem>
        <MenuItem style={{padding: '14px 16px', display: 'flex', justifyContent: 'space-between'}} onClick={(e) => handleClose(e,'/new-payment')}>
          <Typography variant='subtitle3' color='#040517'>New payment</Typography>
          {location.pathname === '/new-payment' && <img src={tick} alt="icon" />}
        </MenuItem>
        <MenuItem style={{padding: '14px 16px', display: 'flex', justifyContent: 'space-between'}} onClick={(e) => handleClose(e,'/payment-detected')}>
          <Typography variant='subtitle3' color='#040517'> Payment detected</Typography>
          {location.pathname === '/payment-detected' && <img src={tick} alt="icon" />}
        </MenuItem>
        <MenuItem style={{padding: '14px 16px', display: 'flex', justifyContent: 'space-between'}} onClick={(e) => handleClose(e,'/payment-success')}>
          <Typography variant='subtitle3' color='#040517'>Success</Typography>
          {location.pathname === '/payment-success' && <img src={tick} alt="icon" />}
        </MenuItem>
        <MenuItem style={{padding: '14px 16px', display: 'flex', justifyContent: 'space-between'}} onClick={(e) => handleClose(e,'/payment-overpaid')}>
          <Typography variant='subtitle3' color='#040517'>Overpaid</Typography>
          {location.pathname === '/payment-overpaid' && <img src={tick} alt="icon" />}
        </MenuItem>
        <MenuItem style={{padding: '14px 16px', display: 'flex', justifyContent: 'space-between'}} onClick={(e) => handleClose(e,'/partial-payment')}>
          <Typography variant='subtitle3' color='#040517'>Partial payment</Typography>
          {location.pathname === '/partial-payment' && <img src={tick} alt="icon" />}
        </MenuItem>
        <MenuItem style={{padding: '14px 16px', display: 'flex', justifyContent: 'space-between'}} onClick={(e) => handleClose(e,'/payment-underpaid')}>
          <Typography variant='subtitle3' color='#040517'>Underpaid</Typography>
          {location.pathname === '/payment-underpaid' && <img src={tick} alt="icon" />}
        </MenuItem>
        <MenuItem style={{padding: '14px 16px', display: 'flex', justifyContent: 'space-between'}} onClick={(e) => handleClose(e,'/expired')}>
          <Typography variant='subtitle3' color='#040517'>Expired</Typography>
          {location.pathname === '/expired' && <img src={tick} alt="icon" />}
        </MenuItem>
        <MenuItem style={{padding: '14px 16px', display: 'flex', justifyContent: 'space-between'}} onClick={(e) => handleClose(e,'/refund')}>
          <Typography variant='subtitle3' color='#040517'>Refund</Typography>
          {location.pathname === '/refund' && <img src={tick} alt="icon" />}
        </MenuItem>
      </MenuMUI>
    </MenuContainer>      
  );
}