import styled from '@emotion/styled';
import { Modal, Box } from '@mui/material';

export const ModalEmail = styled(Modal)({
  display: 'flex',
  justifyContent: 'center',
  alignItems:"center",
  zIndex: 10000,
  "@media (max-width: 450px)": {
    overflowY: 'auto',
    height: '100%'
  }
});

export const BoxEmail = styled(Box)({
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '536px',
    width: '100%',
    height: 'auto',
    borderRadius: 16,
    border: 0,
    outline: 'none',
    "@media (max-width: 450px)": {
        borderRadius: 0,
        height: 'inherit',
        overflow: 'auto'
    }
})

export const ModalEmailHeader = styled('div')({
    display: 'flex',
    padding: '20px 24px',
    marginBottom: 24,
    borderBottom: '1px solid rgba(4, 5, 23, 0.08)',
    "@media (max-width: 450px)": {
        
    }
})

export const ModalEmailInnerContainer = styled('div')({
    padding: '0 24px 24px',
    "@media (max-width: 450px)": {
        
    }
})
export const ModalEmailItem = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 14,
    marginBottom: 14,
    borderBottom: '1px solid rgba(4, 5, 23, 0.08)',
    "@media (max-width: 450px)": {
        
    }
})

export const ModalMessage = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 8px',
    margin: '24px 0',
    borderRadius: 8,
    backgroundColor: 'rgba(4, 5, 23, 0.04)',
    textAlign: 'center',
    "@media (max-width: 450px)": {
        
    }
})



