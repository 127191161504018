import palette_light from './palette_light'
import palette_dark from './palette_dark'
import { breakpoints } from './breakpoints'
import { typography } from './typography'
import components from './components'

export const theme = (mode = 'light') => {
  const palette = mode === 'light' ? palette_light : palette_dark

  return {
    name: 'coinspaid',
    themeName: 'CoinsPaid',
    default: true,
    shadows: [
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    palette,
    components,
    breakpoints,
    mixins: {
      toolbar: {
        minHeight: 96,
      },
      menu: {
        logoMargin: 24, // HACK: delete when navigation menu is redesigned
      },
      topbar: { minHeight: 72, containerWidth: 168 },
      popup: {
        width: 432,
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography,
    title: {
      app: 'Cryptoprocessing by CoinsPaid',
    },
  }
}
