import {useEffect} from 'react';
import { Link } from 'react-router-dom';
import Typography from '../../../../components/Typography';
import crypto from './icons/crypto.svg';
import apple from './icons/apple.svg';
import google from './icons/google.svg';
import visa from './icons/visa.svg';
import mastercard from './icons/mastercard.svg';
import onlineBank from './icons/onlineBank.svg';
import addCard from './icons/addCard.svg';
import radioMock from './icons/radioMock.svg';
import Slide from '@mui/material/Slide';

import {
  ModalDeposit,
  BoxDeposit,
  HeadingDeposit,
  ColumnDeposit,
  ColumnDepositSubject,
  CaptionContainer,
  CaptionItemContainer,
  CaptionItemRadio,
  SuccessModalContainer
} from './Deposit.style';
import {
  LandingButton,
  LandingTypographyH3,
  LandingTypographyH4,
  LandingTypographyH6,
  LandingTypographyP
} from '../../Landing.style'

const payments = [
  {
    name: 'Pay with Crypto',
    selected: true,
    icon: crypto
  },
  {
    name: 'Apple Pay',
    selected: false,
    icon: apple
  },
  {
    name: 'Google Pay',
    selected: false,
    icon: google
  },
  {
    name: 'Mastercard',
    selected: false,
    icon: mastercard
  },
  {
    name: 'Visa',
    selected: false,
    icon: visa
  },
  {
    name: 'Online Bank Transfer',
    selected: false,
    icon: onlineBank
  },
  {
    name: 'Add Card',
    selected: false,
    icon: addCard
  }
]

const CaptionItem = ({name, selected, icon}) => {
  return (
    <CaptionItemContainer selected={selected}>
      <img style={{marginRight: '12px',}} src={icon} alt="icon" />
      <Typography variant='caption3' color='#040517'>
        {name}
      </Typography>
      {selected ? <img style={{marginLeft: 'auto',}} src={radioMock} alt="icon" /> : <CaptionItemRadio/>}
    </CaptionItemContainer>
  )
}

export const DepositModal = ({
  popupIsOpen, 
  openPopup, 
  isSuccessPayment, 
  setIsSuccessPayment, 
  isOverpaidPayment, 
  setIsOverpaidPayment,
  isUnderpaidPayment, 
  setIsUnderpaidPayment
}) => {
  const handleClose = () => openPopup(false);

  useEffect(() => {
    return () => window.history.replaceState({}, ''),
    []
  })

  return (
    <ModalDeposit
        isSuccessPayment={isSuccessPayment || isOverpaidPayment || isUnderpaidPayment}
        open={popupIsOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction='up' in={popupIsOpen}>
        <BoxDeposit isSuccessPayment={isSuccessPayment || isOverpaidPayment || isUnderpaidPayment}>
          <HeadingDeposit>
            <Typography variant='subtitle2' color='#040517'></Typography>
            <svg style={{cursor: 'pointer'}} onClick={handleClose} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M13.4247 3.42417C13.659 3.18985 13.659 2.80995 13.4247 2.57564C13.1903 2.34132 12.8104 2.34132 12.5761 2.57564L8.00039 7.15137L3.42465 2.57564C3.19034 2.34132 2.81044 2.34132 2.57613 2.57564C2.34181 2.80995 2.34181 3.18985 2.57613 3.42417L7.15186 7.9999L2.57613 12.5756C2.34181 12.81 2.34181 13.1899 2.57613 13.4242C2.81044 13.6585 3.19034 13.6585 3.42465 13.4242L8.00039 8.84843L12.5761 13.4242C12.8104 13.6585 13.1903 13.6585 13.4247 13.4242C13.659 13.1899 13.659 12.81 13.4247 12.5756L8.84892 7.9999L13.4247 3.42417Z" fill="#536479"/>
            </svg>
          </HeadingDeposit>
          {(isSuccessPayment || isOverpaidPayment) && (
            <SuccessModalContainer>
              <LandingTypographyH3 style={{ marginBottom: 16}} depositModal secondary>YOU'RE GOING FLYING!</LandingTypographyH3>
              <LandingTypographyP style={{ marginBottom: 24, color: '#536479'}}>Thank you for your purchase. Our team will contact you shortly regarding further details.</LandingTypographyP>
              <LandingButton style={{ width: '100%', marginBottom: 40}} onClick={handleClose}>GOT IT</LandingButton>
            </SuccessModalContainer>
          )}
          {isUnderpaidPayment && (
            <SuccessModalContainer>
              <LandingTypographyH3 style={{ marginBottom: 16}} depositModal secondary>ORDER CANCELLED</LandingTypographyH3>
              <LandingTypographyP style={{ marginBottom: 24, color: '#536479'}}>We were unable to receive payment for your order. Please try again.</LandingTypographyP>
              <LandingButton style={{ width: '100%', marginBottom: 40}} onClick={handleClose}>GOT IT</LandingButton>
            </SuccessModalContainer>          
          )}
          {(!isSuccessPayment && !isOverpaidPayment && !isUnderpaidPayment) && <>
          <ColumnDeposit secondary>
          <LandingTypographyH4 secondary>Payment Methods</LandingTypographyH4>
            <CaptionContainer>
              {payments.map(payment => {
                return <CaptionItem key={payment.name} icon={payment.icon} name={payment.name} selected={payment.selected} />
              })}
            </CaptionContainer>
          </ColumnDeposit>
          <ColumnDeposit>
          <LandingTypographyH4 secondary>Payment Details</LandingTypographyH4>
          <LandingTypographyH6 style={{marginTop: 24, marginBottom: -24}}>Amount</LandingTypographyH6>
          <LandingTypographyH3 secondary depositModal>1250 EUR</LandingTypographyH3>
            <ColumnDepositSubject>
              <LandingTypographyH6 style={{marginBottom: -4}}>Order information</LandingTypographyH6>
              <LandingTypographyP>Purchase Tandem Skydiving certificate from JUMPSKY.</LandingTypographyP>
            </ColumnDepositSubject>
            <LandingButton component={Link} depositModal to='/new-payment'>CONTINUE</LandingButton>
          </ColumnDeposit>
          </>}
        </BoxDeposit>
        </Slide>
    </ModalDeposit>
  )
}
