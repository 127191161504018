//To be refactored, there were no time :(
import { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import {format} from 'date-fns';
import Typography from '../../components/Typography';
import Tooltip from '../../components/Tooltip';
import Select from '../../components/Select';
import ButtonField from '../../components/Button';
import TextField from '../../components/TextField';
import Checkbox from '../../components/Checkbox';
import {
  CheckboxContainerMock,
  Spinner,
  TypographyEl,
  AlertMock,
  PaymentExpiredContainer
} from './NewPayment.style';
import qrMock from '../../assets/qr-mock.png';
import alert from '../../assets/alert.svg';
import copy from '../../assets/copy.svg';
import info from '../../assets/info.svg';
import spiner from '../../assets/spinner.svg';
import alertInfo from '../../assets/alert-info.svg';
import { currency } from './currency';
import { millisToMinutesAndSeconds } from '../../utils/mlToSec';
import { EmailModal } from './EmailModal';

const Icon = ({onClick}) => <svg style={{ cursor: 'pointer'}} onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M2.55806 2.55806C2.80214 2.31398 3.19786 2.31398 3.44194 2.55806L8 7.11612L12.5581 2.55806C12.8021 2.31398 13.1979 2.31398 13.4419 2.55806C13.686 2.80214 13.686 3.19786 13.4419 3.44194L8.88388 8L13.4419 12.5581C13.686 12.8021 13.686 13.1979 13.4419 13.4419C13.1979 13.686 12.8021 13.686 12.5581 13.4419L8 8.88388L3.44194 13.4419C3.19786 13.686 2.80214 13.686 2.55806 13.4419C2.31398 13.1979 2.31398 12.8021 2.55806 12.5581L7.11612 8L2.55806 3.44194C2.31398 3.19786 2.31398 2.80214 2.55806 2.55806Z" fill="white"/>
</svg>

export const NewPayment = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const timerRef15ID = useRef()
  const timerRef15DetectedID =  useRef()
  const timerRefSuccessID = useRef()
  const [checked, handleChange] = useState(false)
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false)
  const [isNewPaymentDetected, setIsNewPaymentDetected] = useState(false)
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false)
  const [isPaymentOverpaid, setIsPaymentOverpaid] = useState(false)
  const [isPaymentExpired, setIsPaymentExpired] = useState(false)
  const [expiredTimer, setExpiredTimer] = useState(900000) //15 min timer
  const [expiredTimerDetectedPayment, setExpiredTimerDetectedPayment] = useState(900000) //15 min timer
  const [isTimeExpired, setTimeExpired] = useState(false)
  const [tooltipIsOpen, toggleTooltip] = useState(true)
  const [emailModalIsOpen, toggleModalEmail] = useState(false)

  const resetPaymentDetectedTimer = () => {
    clearInterval(timerRef15DetectedID.current)
    setExpiredTimerDetectedPayment(900000)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
        clearInterval(timerRef15ID.current)
        clearTimeout(timerRefSuccessID.current)
        window.history.replaceState({}, '')
        handleChange(false)
        resetPaymentDetectedTimer()
      }
  }, [])

  useEffect(() => {
    if (location.pathname === '/new-payment') {
      setIsPaymentInProgress(false);
      setIsNewPaymentDetected(false);
      setIsPaymentOverpaid(false);
      setIsPaymentExpired(false);
      setIsPaymentSuccess(false);
      handleChange(false)
      clearTimeout(timerRefSuccessID.current)
      window.scrollTo(0, 0);
      resetPaymentDetectedTimer();
    }
    if (location.pathname === '/payment-detected') {
      setIsPaymentInProgress(false);
      setIsNewPaymentDetected(true);
      setIsPaymentOverpaid(false);
      setIsPaymentExpired(false);
      setIsPaymentSuccess(false);
      clearInterval(timerRef15ID.current)
      setExpiredTimer(900000)
      handleChange(false)
      window.scrollTo(0, 0);
      startDetectedPaymentTimer();
    }
    if (location.pathname === '/payment-success') {
      setIsPaymentInProgress(false);
      setIsNewPaymentDetected(false);
      setIsPaymentOverpaid(false);
      setIsPaymentExpired(false);
      setIsPaymentSuccess(true);
      clearInterval(timerRef15ID.current)
      setExpiredTimer(900000)
      handleChange(false)
      clearTimeout(timerRefSuccessID.current)
      window.scrollTo(0, 0);
      resetPaymentDetectedTimer();
    }
    if (location.pathname === '/expired') {
      setIsPaymentInProgress(false);
      setIsNewPaymentDetected(false);
      setIsPaymentSuccess(false);
      setIsPaymentOverpaid(false);
      setIsPaymentExpired(true);
      clearInterval(timerRef15ID.current)
      setExpiredTimer(900000)
      handleChange(false)
      clearTimeout(timerRefSuccessID.current)
      window.scrollTo(0, 0);
      resetPaymentDetectedTimer()
    }

    if (location.pathname === '/payment-overpaid') {
      setIsPaymentInProgress(false);
      setIsNewPaymentDetected(false);
      setIsPaymentSuccess(false);
      setIsPaymentExpired(false);
      setIsPaymentOverpaid(true);
      clearInterval(timerRef15ID.current)
      setExpiredTimer(900000)
      handleChange(false)
      clearTimeout(timerRefSuccessID.current)
      window.scrollTo(0, 0);
      resetPaymentDetectedTimer()
    }
  }, [location])

  useEffect(() => {
    if (isNewPaymentDetected) {
      timerRefSuccessID.current = setTimeout(() => {
        navigate('/payment-success')
        resetPaymentDetectedTimer()
      }, 60000)//redirect in 60 sec
    }
  }, [isNewPaymentDetected])

  useEffect(() => {
    if (Number(expiredTimer) === 0) {
      setIsPaymentInProgress(false)
      setTimeExpired(true)
    }
  }, [expiredTimer])

  useEffect(() => {
    if (isTimeExpired) {
     navigate('/expired')
     clearInterval(timerRef15ID.current)
    }
  }, [isTimeExpired])

  const startTimer = (e) => {
    e.preventDefault();
    setIsPaymentInProgress(true);
    timerRef15ID.current = setInterval(() => {
      setExpiredTimer(prevValue => prevValue - 1000)
    }, 1000) // count 1 sec 15 min
  }

  const startDetectedPaymentTimer = () => {
    timerRef15DetectedID.current = setInterval(() => {
      setExpiredTimerDetectedPayment(prevValue => prevValue - 1000)
    }, 1000) // count 1 sec 15 min
  }

  const currentDate = () => format(new Date(), 'MMMM d, yyyy hh:mm a');

  const onActionButtonClick = () => toggleModalEmail(true)

  return (
    <>
      { isPaymentExpired && (
        <PaymentExpiredContainer>
          <Typography style={{marginBottom: 4}} variant='h1' color='#FF4754'>Payment request expired</Typography>
          <Typography style={{marginBottom: 24}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>
            The timer has run out. Please create a new payment request.
          </Typography>
          <ButtonField style={{marginBottom: 16, width: '100%'}} size='large' onClick={() => navigate('/', { state: { location: '/expired' }})}>Return to merchant</ButtonField>
      </PaymentExpiredContainer>
      )}
      {
        (isPaymentSuccess || isPaymentOverpaid) && (
        <>
          <Typography style={{marginBottom: 4}} variant='h1' color='#040517'>Success</Typography>
          <Typography style={{marginBottom: 24, paddingBottom: 24, borderBottom: '1px solid rgba(4, 5, 23, 0.08)'}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>Payment complete</Typography>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 24}}>
            <TextField size='medium' style={{width: 220, height: 56, marginRight: 16}} disabled label="Amount" defaultValue="1357.53 USDT"/>
            <TextField style={{width: 220, height: 56}} disabled label="You paid" value={`${isPaymentSuccess ? 1357.53 : 1507.53} USDT`}/>
          </div>
          <TextField fullWidth style={{height: 56}} disabled label="Address" defaultValue="TR3t6a3oK5xK5N8b4K1Jv2L3pW4ZmU4sP3"/>
          {
            isPaymentOverpaid &&
            <>
            <AlertMock>
            <Typography variant='caption2' color='rgba(20, 75, 255, 1)'>You overpaid 150 USDT. We’ve sent the refund instructions to <span style={{fontWeight: 600, textDecoration: 'none', outline: 'none'}}>user@mail.com</span></Typography>
            <img src={alertInfo} alt='icon'/>
            </AlertMock>
            <Tooltip icon={<Icon onClick={() => toggleTooltip(false)}/>}
              text='Check the example to see what your customers will receive by email'
              placement='bottom'
              open={tooltipIsOpen}
              title='How does this email look?'
              actionButtonText='Preview'
              onActionButtonClick={onActionButtonClick}
              PopperProps={{style:{zIndex:0}}}
            >
            </Tooltip>
            </>
          }
          <div>
          <Typography style={{marginBottom: 4, marginTop: 24,}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>TXID</Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
          <TypographyEl>
          <Typography style={{
              marginBottom: 24, 
              width: '100%', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              display: 'inline-block', 
            }}
            variant='subtitle1' color='#040517'>4428945e6bc7246ca46d1e7f3e89f0136682e7e357bd3c3a9вв27474531c0b63</Typography>
            </TypographyEl>
            <img style={{marginLeft: 16, marginBottom: 24}} src={copy} alt="icon"/>
            </div>
        </div>
        <ButtonField style={{marginBottom: 16}} size='large' onClick={() => navigate('/', { state: { location: isPaymentSuccess ? '/payment-success' : isPaymentOverpaid ? '/payment-overpaid' : '' }})}>Return to merchant</ButtonField>
        </>
        )
      }
      {(isPaymentInProgress || isNewPaymentDetected) &&
      <>
        <div style={{display: 'flex', marginBottom: -26}}>
          <img style={{marginLeft: -26, marginTop: -8, width: 196, height: 196 }} src={qrMock} alt="qr"/>
          <div>
          <Typography style={{marginBottom: 4}} variant='h1' color='#040517'>{isPaymentInProgress? 'New payment' : 'Payment detected'}</Typography>
          <Typography style={{marginBottom: 8}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>Created: {currentDate()}</Typography>
          </div>
        </div>
        <div style={{marginBottom: 24, paddingBottom: 24, borderBottom: '1px solid rgba(4, 5, 23, 0.08)'}}>
          <div style={{display: 'flex', flexShrink: 0, marginBottom: 8}}>
            <Typography style={{minWidth: 123, display: 'inline-flex'}} variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Description:</Typography>
            <Typography variant='subtitle3' color='#040517'>A certificate granting one parachute jump</Typography>
          </div>
          <div style={{marginBottom: 8}}>
            <Typography style={{width: 123, display: 'inline-block'}} variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Time remaining:</Typography>
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
              <Typography style={{ display: 'inline-flex', alignItems: 'center', width: 39}} variant='subtitle3' color='#13C270'>
                {isNewPaymentDetected ? millisToMinutesAndSeconds(expiredTimerDetectedPayment) : millisToMinutesAndSeconds(expiredTimer)}
              </Typography>
              <div>
                <Tooltip
                  placement='top'
                  title='When the timer runs out, the payment request will expire'
                >
                  <img style={{marginLeft: 4, marginTop: 3}} src={info} alt="icon"/>
              </Tooltip>
            </div>
            </div>
          </div>
          <div style={{marginBottom: 8}}>
            <Typography style={{width: 123, display: 'inline-block'}} variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Amount:</Typography>
            <Typography style={{}} variant='subtitle3' color='#040517'>1250 EUR</Typography>
          </div>
          <div style={{marginBottom: 8}}>
            <Typography style={{width: 123, display: 'inline-block'}} variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Min deposit:</Typography>
            <Typography style={{}} variant='subtitle3' color='#040517'>5 USDT</Typography>
          </div>
        </div>
        <div style={{marginBottom: 24, display: 'flex',padding: 16, flexDirection: 'column', borderRadius: 8,background: 'rgba(4, 5, 23, 0.04)'}}>
          <Typography style={{marginBottom: 8, display: 'flex', alignItems: 'center'}} variant='subtitle1' color='#FF7A05'><img style={{marginRight: 4}} src={alert} alt="icon"/>Pay attention!</Typography>
          <Typography style={{marginBottom: 4, display: 'flex'}} variant='caption3' color='rgba(4, 5, 23, 0.56)'><span>&bull;</span><span style={{paddingLeft: 8}}>Send only <span style={{fontWeight: 600}}>USDT using TRC-20</span> standard. Sending any other currency or using other standard may result in loss of funds</span></Typography>
          <Typography style={{display: 'flex'}} variant='caption3' color='rgba(4, 5, 23, 0.56)'><span>&bull;</span><span style={{paddingLeft: 8}}>If the wallet you are using <span style={{fontWeight: 600}}>charges a fee</span> that reduces the total amount that is sent, please send enough to cover it</span></Typography>
        </div>
        <div>
          <Typography style={{marginBottom: 8}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>Amount to pay</Typography>
          <Typography style={{marginBottom: 24, fontSize: 24}} variant='body3' color='#040517'>1357.53 USDT<img style={{marginLeft: 4}} src={copy} alt="icon"/></Typography>
        </div>
        {
          isNewPaymentDetected ? (<div>
          <Typography style={{marginBottom: 4}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>TXID</Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <TypographyEl>
            <Typography style={{marginBottom: 24, width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block'}} variant='subtitle1' color='#040517'>4428945e6bc7246ca46d1e7f3e89f0136682e7e357bd3c3a9вв27474531c0b63</Typography>
            </TypographyEl>
            <img style={{marginLeft: 4, marginBottom: 24}} src={copy} alt="icon"/>
          </div>
          </div>) : (<TextField readOnly style={{marginBottom: 24}} label="USDT address to pay" defaultValue="TR3t6a3oK5xK5N8b4K1Jv2L3pW4ZmU4sP3"/>)
        }
        <ButtonField size='large' startIcon={<Spinner src={spiner} alt="icon"/>} disabled={true}>Waiting for payment</ButtonField>
        <Typography style={{marginTop: 24, textAlign: 'center'}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>
          By making the payment you agree to the <a style={{ textDecoration: 'none', color: '#144BFF' }} href="https://coinspaid.com/terms-of-use/" target='_blank'rel="noreferrer"> Terms of Use</a> and <a style={{textDecoration: 'none', color: '#144BFF'}} href="https://coinspaid.com/privacypolicy/" target='_blank' rel="noreferrer">Privacy Policy</a>.
          We will send payment information to <span style={{ color: '#040517', fontWeight: 700 }}>user@mail.com</span></Typography>
      </>
      } 
      { !isPaymentInProgress && !isNewPaymentDetected  && !isPaymentSuccess && !isPaymentExpired && !isPaymentOverpaid &&
        <>
          <Typography style={{marginBottom: 4}} variant='h1' color='#040517'>New payment</Typography>
          <Typography style={{marginBottom: 8}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>Created: {currentDate()}</Typography>
          <Typography style={{paddingBottom: 24, marginBottom: 24, display: 'block',borderBottom: '1px solid rgba(4, 5, 23, 0.08)'}} variant='subtitle3' color='#040517'>A JumpSky certificate granting one parachute jump</Typography>
          <Typography style={{marginBottom: 4}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>Amount</Typography>
          <Typography style={{marginBottom: 24, fontSize: 24}} variant='body3' color='#040517'>1250 EUR</Typography>
          <Typography style={{marginBottom: 24}} variant='subtitle3' color='#040517'>Choose your payment currency</Typography>
          <Select isSearchable={false} isIcon style={{marginBottom: 8}} value={1} label={'Currency'} options={currency} />
          <Typography style={{marginBottom: 24}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>Ensure network/standard matches your crypto wallet</Typography>
          <TextField label="Email Address for Receipt*" defaultValue="user@mail.com"/>
          <Typography style={{marginBottom: 8, marginTop: 8}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>If the payment amount doesn’t match the amount stated above, we’ll send an email on how to recover your funds</Typography>
          <CheckboxContainerMock>
            <Checkbox
              required
              checked={checked}
              onChange={(e) => handleChange(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography variant='caption3' color='#040517'>I agree to the 
            <a style={{ textDecoration: 'none', color: '#144BFF' }} href="https://coinspaid.com/terms-of-use/" target='_blank'rel="noreferrer"> Terms of Use</a> and <a style={{textDecoration: 'none', color: '#144BFF'}} href="https://coinspaid.com/privacypolicy/" target='_blank' rel="noreferrer">Privacy Policy</a></Typography>
          </CheckboxContainerMock>
          <ButtonField size='large' disabled={!checked} onClick={startTimer}>Pay</ButtonField>
          <Typography style={{marginTop: 24, textAlign: 'center'}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>Press <span style={{ color: '#040517', fontWeight: 700 }}>"Pay"</span> only when you're ready - you'll have <span style={{ color: '#040517', fontWeight: 700 }}>15 minutes to complete</span> the payment.</Typography>
        </>
      }
      <EmailModal emailModalIsOpen={emailModalIsOpen} closeModalEmail={() => toggleModalEmail(false)} />
    </>
  )
}
