import PropTypes from 'prop-types'
import React from 'react'
import { TextField as TextFieldMui, InputAdornment } from '@mui/material'
import clsx from 'clsx'
import styles from './styles'

/**
 * Returns MUI TextField. Use typography to present your design and content as clearly and efficiently as possible.
 *
 * @param props
 * @param {ReactElement} props.children - The content of the component.
 *
 * @param {bool} props.autoFocus - If `true`, the `input` element is focused during the first mount.
 *
 * @param {ReactElement} props.children
 *
 * @param {string} props.className
 *
 * @param {string} props.color - The color of the component.
 * It supports those theme colors that make sense for this component.
 *
 * @param {string} props.defaultValue -  The default value. Use when the component is not controlled.
 *
 * @param {bool} props.disabled - If `true`, the component is disabled.
 *
 * @param {bool} props.error - If `true`, the label is displayed in an error state.
 *
 * @param {bool} props.fullWidth - If `true`, the input will take up the full width of its container.
 *
 * @param {string} props.helperText - The helper text content.
 *
 * @param {string} props.id - The id of the `input` element.
 *
 * @param {string} props.label - Pass a ref to the `input` element.
 *
 * @param {number, string} props.maxRows - Maximum number of rows to display when multiline option is set to true.
 *
 * @param {number, string} props.minRows - Minimum number of rows to display when multiline option is set to true.
 *
 * @param {bool} props.multiline - If `true`, a `textarea` element is rendered instead of an input.
 *
 * @param {string} props.name - Name attribute of the `input` element.
 *
 * @param {func} props.onChange
 *
 * @param {func} props.onFocus
 *
 * @param {string} props.placeholder - The short hint displayed in the `input` before the user enters a value.
 *
 * @param {number, string} props.rows - Number of rows to display when multiline option is set to true.
 *
 * @param {string} props.size - If this option is set you must pass the options of the select as children.
 *
 * @param {string} props.type - Type of the `input` element. It should be [a valid HTML5 input type].
 *
 * @param {any} props.value - The value of the `input` element, required for a controlled component.
 *
 * @param {string} props.variant - The variant to use.
 * */

const TextField = (props) => {
  const {
    label,
    className,
    onChange,
    onFocus,
    error,
    helperText,
    readOnly,
    disabled,
    placeholder,
    name,
    type,
    rows,
    fullWidth,
    value,
    startAdornment,
    endAdornment,
    variant,
    autoFocus,
    id,
    maxRows,
    minRows,
    multiline,
    onBlur,
    defaultValue,
    inputProps,
    InputProps,
    InputLabelProps,
    inputComponent,
    size,
    dataTestId,
    // TODO: remove after refactoring
    withDividerBeforeContent,
    withDividerAfterContent,
    dividerVariant,
    ...params
  } = props
  const classes = styles()

  return (
    <TextFieldMui
      id={id}
      maxRows={maxRows}
      minRows={minRows}
      multiline={multiline}
      onBlur={onBlur}
      className={clsx(className, { [classes.labelAdornment]: !!endAdornment })}
      variant={variant}
      label={label}
      name={name}
      type={type}
      rows={rows}
      value={value}
      autoFocus={autoFocus}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      error={!!error}
      disabled={disabled}
      fullWidth={fullWidth}
      helperText={helperText}
      defaultValue={defaultValue}
      size={size}
      onWheel={(event) => event.target.blur()}
      inputProps={{
        ...inputProps,
        'data-testid': `text-field-${dataTestId}`,
      }}
      InputProps={{
        ...InputProps,
        ...(startAdornment
          ? { startAdornment: <InputAdornment position='start'>{startAdornment}</InputAdornment> }
          : {}),
        ...(endAdornment ? { endAdornment: <InputAdornment position='end'>{endAdornment}</InputAdornment> } : {}),
        ...(inputComponent ? { inputComponent } : {}),
        ...(readOnly ? { readOnly } : {}),
      }}
      InputLabelProps={InputLabelProps || { shrink: value ? true : undefined }}
      {...params}
    />
  )
}

TextField.propTypes = {
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it [following the specification]
   * (https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
   */
  autoComplete: PropTypes.string,
  /**
   * If `true`, the `input` element is focused during the first mount.
   * @default false
   */
  autoFocus: PropTypes.bool,
  /**
   * @ignore
   */
  children: PropTypes.node,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary']), PropTypes.string]),
  /**
   * The default value. Use when the component is not controlled.
   */
  defaultValue: PropTypes.any,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If string, the label is displayed in an error state.
   * @default false
   */
  error: PropTypes.bool,
  /**
   * If `true`, the input will take up the full width of its container.
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * The helper text content.
   */
  helperText: PropTypes.node,
  /**
   * The id of the `input` element.
   * Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id: PropTypes.string,
  /**
   * Pass a ref to the `input` element.
   */
  label: PropTypes.node,
  /**
   * Maximum number of rows to display when multiline option is set to true.
   */
  maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Minimum number of rows to display when multiline option is set to true.
   */
  minRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * If `true`, a `textarea` element is rendered instead of an input.
   * @default false
   */
  multiline: PropTypes.bool,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * @ignore
   */
  onBlur: PropTypes.func,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /**
   * @ignore
   */
  onFocus: PropTypes.func,
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * If `true`, the label is displayed as required and the `input` element is required.
   * @default false
   */
  required: PropTypes.bool,
  /**
   * Number of rows to display when multiline option is set to true.
   */
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Render a [`Select`](/material-ui/api/select/) element while
   * passing the Input element to `Select` as `input` parameter.
   * If this option is set you must pass the options of the select as children.
   * @default false
   */
  select: PropTypes.bool,
  /**
   * Render a [`Select`](/api/select/) element while passing the Input element to `Select` as `input` parameter.
   * If this option is set you must pass the options of the select as children.
   * @default false
   */
  size: PropTypes.oneOfType([PropTypes.oneOf(['medium', 'small']), PropTypes.string]),
  /**
   * Type of the `input` element. It should be [a valid HTML5 input type].
   */
  type: PropTypes.string,
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value: PropTypes.any,
  /**
   * The variant to use.
   * @default 'outlined'
   */
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
}

TextField.defaultProps = {
  variant: 'outlined',
  inputProps: {},
}

export default TextField
