import styled from '@emotion/styled';
import { Button, Typography, Container } from '@mui/material';

export const LandingButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "depositModal"
})((props) => ({
    backgroundColor: '#FEDD67',
    borderRadius: '100px',
    color: '#061A2D',
    fontFamily: 'Outfit',
    fontSize: '16px',
    lineHeight: '150%',
    textTransform: 'uppercase',
    fontWeight: 700,
    "&:hover": {
        backgroundColor: "#FFC000"
    },
    width: props.depositModal ? "290px" : "200px",
    height: "56px",
    "@media (max-width: 450px)": {
        fontSize: '14px',
        width: props.depositModal ? "100%" : '183px',
        height: "53px",
      },
}));

export const LandingTypographyH1 = styled(Typography)({
    color: 'white',
    fontFamily: 'Outfit',
    fontSize: '96px',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '110%',
    marginBottom: '24px',
    "@media (max-width: 450px)": {
        fontSize: '52px',
      },
})

export const LandingTypographyH3 = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "secondary" && prop !== "depositModal"
})((props) => ({
    color: props.secondary ? '#061A2D' : 'white',
    fontFamily: props.depositModal ? 'Outfit' : 'Manrope',
    fontSize: props.depositModal ? '32px' : '24px',
    fontStyle: 'normal',
    fontWeight: props.depositModal ? 800 : 500,
    lineHeight: '150%',
    marginTop: props.secondary ? '24px' : '94px',
    marginBottom: props.secondary ? '0' : '24px',
    "@media (max-width: 450px)": {
        fontSize: props.depositModal ? '24px' : '16px',
        marginTop: props.secondary ? '24px' : '0',
        marginBottom: 0,
      },
}));

export const LandingTypographyH4 = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "secondary"
})((props) => ({
    color: props.secondary ? '#061A2D' : 'white',
    fontFamily: 'Outfit',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
    textTransform: 'uppercase',
    "@media (max-width: 450px)": {
        fontSize: '14px',
      },
}));

export const LandingTypographyH2 = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "secondary" && prop !=="tertiary"
})((props) => ({
    color: props.secondary ? '#1968B3' : props.tertiary ? 'white' : '#061A2D ',
    fontFamily: 'Outfit',
    fontSize: '60px',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '110%',
    textTransform: 'uppercase',
    margin: props.tertiary ? '24px 0' : 0,
    textAlign: props.tertiary ? 'center' : 'left',
    "@media (max-width: 450px)": {
        fontSize: '36px',
      },
}));

export const LandingTypographyH6 = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "secondary"
})((props) => ({
    color: props.secondary ? 'white' : '#536479',
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
}));

export const LandingTypographyP = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "secondary"
})((props) => ({
    color: props.secondary ? 'white' : '#061A2D',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    "@media (max-width: 450px)": {
        fontSize: '14px',
      },
}));
    
export const HeroContainer = styled(Container)(`
    position: relative;
`)

export const HeroContainerInner = styled(Container)({
    position: 'absolute',
    top: '40px',
    left: '80px',
    maxWidth: '620px',
    "@media (max-width: 450px)": {
        top: '100%',
        transform: 'translateY(-120%)',
        left: '24px',
        maxWidth: '320px',
      },
})

export const AboutContainer = styled(Container)({
    padding: '160px 80px',
    display:'grid',
    gridTemplateColumns: '1fr 950px',
    "@media (max-width: 450px)": {
        display: 'flex',
        flexDirection: 'column',
        padding: '60px 24px',
      },
})

export const AboutContainerInner = styled(Container)({
    "@media (max-width: 450px)": {
        marginTop: 24,
    }
})

export const AboutContainerImages = styled(Container)({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '80px',
    "@media (max-width: 450px)": {
        flexDirection: 'column',
        marginBottom: '40px',
      },
})

export const AboutContainerInfo = styled(Container)({
    display: 'flex',
    padding: '40px',
    borderRadius: '12px',
    backgroundColor: '#E9F3FC',
    "@media (max-width: 450px)": {
        flexDirection: 'column',
        backgroundColor: '#F0F3F6'
      },
});

export const AboutContainerInfoItem = styled(Container)({
    paddingLeft: '0!important',
    paddingRight: '0!important',
    "@media (max-width: 450px)": {
        marginBottom: 24
      },
});

export const PricingContainer = styled(Container)({
    padding: '67px 124px 56px 80px',
    backgroundColor: '#1968B3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "@media (max-width: 450px)": {
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        padding: '24px',
      },
    })

    export const PricingContainerInner = styled('div')({
        maxWidth: 1280,
        display: 'flex',
        "@media (max-width: 450px)": {
            display: 'block',
          },
        })

export const PricingContainerImages = styled(Container)({
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    rowGap: '50px',
    "@media (max-width: 450px)": {
        display: 'flex',
      },
})

export const PricingContainerText = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 620,
    "@media (max-width: 450px)": {
        maxWidth: 320,
      },
})

export const FooterContainer = styled(Container)(`
    max-width: 620px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`)

export const HeroLogo = styled('img')({
    maxHeight: '42px',
    maxWidth: '198px',
    height: '100%',
    width: '100%',
    "@media (max-width: 450px)": {
        display: 'none',
      },
})

export const HeroLogoMobile = styled('img')({
    display: 'none',
    "@media (max-width: 450px)": {
        maxHeight: '21px',
        maxWidth: '99px',
        height: '100%',
        width: '100%',
        display: 'block',
        position: 'absolute',
        top: '24px',
        left: '24px'
      },
})

export const AboutImage1 = styled('img')({
    maxHeight: 386, 
    maxWidth: 290, 
    height: '100%', 
    width: '100%', 
    paddingTop: 86,
    "@media (max-width: 450px)": {
        maxHeight: '416px',
        maxWidth: '100%',
        paddingTop: 40,
      },
})

export const AboutImage2 = styled('img')({
    maxHeight: 218, 
    maxWidth: 290, 
    height: '100%', 
    width: '100%', 
    marginRight: 40, 
    marginLeft: 40,
    "@media (max-width: 450px)": {
        maxHeight: '234px',
        maxWidth: '100%',
        marginRight: 0, 
        marginLeft: 0,
        marginTop: 40,
        marginBottom: 40,
      },
})

export const AboutImage3 = styled('img')({
    maxHeight: 386, 
    maxWidth: 290, 
    height: '100%', 
    width: '100%', 
    "@media (max-width: 450px)": {
        maxHeight: '416px',
        maxWidth: '100%',
      },
})

export const PricingImage1 = styled('img')({
    maxHeight: 290, 
    maxWidth: 290, 
    height: '100%', 
    width: '100%',
    "@media (max-width: 450px)": {
        maxHeight: 202,
        maxWidth: 202,
        transform: 'translateX(-40px)'
      },
})

export const PricingImage2 = styled('img')({
    maxHeight: 200, 
    maxWidth: 200, 
    height: '100%', 
    width: '100%', 
    transform: 'translateX(150px)',
    "@media (max-width: 450px)": {
        maxHeight: 160, 
        maxWidth: 160,
        transform: 'translateX(25px)'
      },
})

export const PricingImage3 = styled('img')({
    maxHeight: 200,
    maxWidth: 200,
    height: '100%',
    width: '100%',
    transform: 'translateX(50px)',
    "@media (max-width: 450px)": {
        maxHeight: 160, 
        maxWidth: 160,
        transform: 'translateX(-35px)'
      },
})

export const PricingImage4 = styled('img')({
    maxHeight: 290, 
    maxWidth: 290, 
    height: '100%', 
    width: '100%', 
    transform: 'translate(-30px)',
    "@media (max-width: 450px)": {
        maxHeight: 202,
        maxWidth: 202,
        transform: 'translateX(40px)'
      },
})

export const LandingFooter = styled('footer')({
    backgroundColor: '#061A2D', 
    padding: '26px 0 40px',
    "@media (max-width: 450px)": {
        padding: '60px 24px 24px'

      },
})

export const CoinsPaidLogo = styled('img')({
    maxHeight: 60, 
    maxWidth: 205, 
    height: '100%', 
    width: '100%', 
    margin: '120px 0 24px',
    "@media (max-width: 450px)": {
        maxHeight: 40,
        maxWidth: 135,
        marginTop: 60
      },
})