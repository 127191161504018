import styled from '@emotion/styled';

export const AlertEmail = styled('div')({
  backgroundColor: 'rgba(255, 122, 5, 0.10)',
  borderRadius: 8,
  padding: '12px 16px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 11,
  "@media (max-width: 450px)": {
   
  }
});