import { Outlet } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {
    PaymentPage,
    PaymentPageLogoContainer,
    PaymentContainerGeneral,
    PaymentPageLogo,
    PaymentHeader,
    PaymentBrandLogo,
    PaymentLang,
    PaymentBrandLogoContainer,
    PaymentContainerInner
  } from './PaymentLayout.style';
import cryptoLogo from '../../assets/crypto-logo.svg';
import brandLogo from '../../assets/jumpsky-logo-s.png';
import langMock from '../../assets/lang-mock.svg';
import Typography from '../../components/Typography';
import { Menu } from './Menu'

export const PaymentLayout = () => {
  const navigate = useNavigate();
  
  return (
    <PaymentPage>
      <PaymentPageLogoContainer>
        <PaymentPageLogo src={cryptoLogo} alt='logo' />
      </PaymentPageLogoContainer>
      <PaymentContainerGeneral>
        <PaymentHeader>
          <PaymentBrandLogoContainer>
          <PaymentBrandLogo onClick={() => navigate('/')} src={brandLogo} alt='logo'/>
          <Typography variant='subtitle2' color='#040517'>JumpSky</Typography>
          </PaymentBrandLogoContainer>
          <PaymentLang src={langMock} alt='lang'/>
        </PaymentHeader>
        <PaymentContainerInner>
          <Outlet />
          <Menu />
      </PaymentContainerInner>
      </PaymentContainerGeneral>
    </PaymentPage>
  )
}
