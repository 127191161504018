import React from 'react'
import { Grid, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { TooltipStyled } from './TooltipStyled'
import ButtonField from '../Button'

/**
 * Returns MUI Tooltip component.
 *
 * @param props
 * @param {node} props.children - Used to render icon or text elements inside
 * the Avatar if src is not set. This can be an element, or just a string.
 *
 * @param {bool} props.arrow - If `true`, adds an arrow to the tooltip.
 *
 * @param {string} props.title - Tooltip title. Zero-length titles string are never displayed.
 *
 * @param {element} props.icon - The icon to display when the component is unchecked.
 *
 * @param {[
 * 'bottom-end',
 * 'bottom-start',
 * 'bottom',
 * 'left-end',
 * 'left-start',
 * 'left','right-end',
 * 'right-start',
 * 'right','top-end',
 * 'top-start',
 * 'top',
 * ]} props.placement - Tooltip placement.
 * */

const TooltipContainer = React.forwardRef(({ children, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
  >
    {children}
  </span>
))

const Tooltip = (props) => {
  const {
    isMenu,
    children,
    arrow,
    placement,
    title,
    icon,
    onClose,
    open,
    text,
    actionButtonText,
    onActionButtonClick,
    ...defaultProps
  } = props

  return (
    <TooltipStyled
    sx={{width: isMenu ? 186 : 'auto',"@media (max-width: 450px)": {width: isMenu ? 186 : 300}}}
      title={(
        <Stack direction='column'>
          {icon && (
            <Grid
              container
              alignItems='center'
              justifyContent='space-between'
              sx={{ mr: 0.5, width: 'auto' }}
            >
              {icon && <>{title} {icon}</> }
            </Grid>
          )}
          {!icon && title}
          {text &&
          <p style={{color: 'rgba(255, 255, 255, 0.48)', fontSize: 12, marginTop: 2, marginBottom: 8, paddingRight: 24}}>{text}</p>}
          {actionButtonText && 
            <a style={{color: '#82A2FA', cursor: 'pointer'}} onClick={onActionButtonClick}>{actionButtonText}</a>
          }
        </Stack>
      )}
      onClose={onClose}
      open={open}
      placement={placement}
      arrow={arrow}
      {...defaultProps}
    >
      <TooltipContainer>{children}</TooltipContainer>
    </TooltipStyled>
  )
}

Tooltip.propTypes = {
  /**
   * Used to render icon or text elements inside the Tooltip. This can be an element, or just a string.
   */
  children: PropTypes.any,
  /**
   * If `true`, adds an arrow to the tooltip.
   * @default false
   */
  arrow: PropTypes.bool,
  /**
   * Tooltip placement.
   * @default 'bottom'
   */
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
  /**
   * Tooltip title. Zero-length titles string are never displayed.
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The icon to display when the component is unchecked.
   */
  icon: PropTypes.element,
  /**
   *  If `true`, tooltip is show.
   */
  open: PropTypes.bool,
}

export default Tooltip

Tooltip.defaultProps = {
  placement: 'top',
  arrow: true,
}
