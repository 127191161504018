export const MuiFormLabel = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      fontSize: '14px',
      '&.Mui-focused': {
        color: palette.primary.main,
      },
    }),
  },
}
