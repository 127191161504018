import { MuiAlert } from './MuiAlert'
import { MuiButton } from './MuiButton'
import { MuiFormLabel } from './MuiFormLabel'
import { MuiInput } from './MuiInput'
import { MuiInputAdornment } from './MuiInputAdornment'
import { MuiInputBase } from './MuiInputBase'
import { MuiInputLabel } from './MuiInputLabel'
import { MuiOutlinedInput } from './MuiOutlinedInput'
import { MuiSelect } from './MuiSelect'
import { UiTextField, UiTextFieldIcon } from './UiTextField'
import { UiTooltip } from './UITooltip'

const components = {
  MuiAlert,
  MuiButton,
  MuiFormLabel,
  MuiInput,
  MuiInputAdornment,
  MuiInputBase,
  MuiInputLabel,
  MuiOutlinedInput,
  MuiSelect,
  UiTextField,
  UiTextFieldIcon,
  UiTooltip,
}

export default components
