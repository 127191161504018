/* eslint-disable */
import React from 'react'
import Select, { components } from 'react-select'

import {
  FormControl,
  FormHelperText,
  Grid,
  Box,
  Typography,
  Checkbox,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import clsx from 'clsx'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _isObjectLike from 'lodash/isObjectLike'

import { styles, selectStyles } from './styles'

const Template = (props, state) => {
  const theme = useTheme()
  const classes = styles()
  const selectClasses = selectStyles(theme, props.isAdornment)

  const SingleValue = ({
    children,
    ...singleValueProperties
  }) => (
    <components.SingleValue {...singleValueProperties}>
      <Value
        iso={children}
        data={singleValueProperties.data}
      />
    </components.SingleValue>
  )

  const Option = ({
    children,
    ...optionProperties
  }) => (
    <components.Option {...optionProperties}>
      <Grid
        container
        alignItems='center'
      >
        {optionProperties.isMulti && !optionProperties.data?.isClearable && (
          <Checkbox
            checked={optionProperties.isSelected}
            color='default'
            className={classes.checkbox}
          />
        )}
        <Value
          iso={children}
          data={optionProperties.data}
        />
      </Grid>
    </components.Option>
  )

  const Value = ({
    iso,
    data,
  }) => (
    <Box container="true">
      <Grid
        alignItems='center'
        wrap='nowrap'
        container
        item
        sm
      >
        {!data?.isClearable && data.icon && <Box className={classes.currencyContainer}>{data.icon}</Box>}
        {data?.isClearable ? (
          iso
        ) : (
          <Typography
            variant='subtitle3'
            className={classes.iso}
          >
            {iso}
          </Typography>
        )}
      </Grid>
      {data?.subtext && (
        <Typography
          noWrap
          variant='caption3'
          className={classes.subtext}
        >
          {data.subtext}
        </Typography>
      )}
    </Box>
  )

  return (
    <FormControl
      className={props.className}
      error={!!props.error || props.hasError}
      fullWidth={props.fullWidth}
      data-testid={`select-${props.dataTestId}`}
      sx={props.sx}
      disabled={props.disabled}
    >
      <Typography
        className={clsx(classes.label, {
          [classes.labelOnTop]:
            state.isFocus
            || (_isObjectLike(props.value) ? !_isEmpty(props.value) : !_isNil(props.value) && props.value !== ''),
          [classes.labelFocus]: state.isFocus,
        })}
      >
        {props.label}
      </Typography>
      <Select
        options={state.options}
        placeholder={props.placeholder || props.label}
        label={props.label}
        maxMenuHeight={props.maxMenuHeight || 192}
        error={!!props.error}
        value={props.isMulti ? props.value : _find(props.options, { value: props.value }) ?? null}
        onChange={props.onChange}
        autoWidth
        styles={{
          ...selectClasses,
          ...props.styles,
        }}
        {...(props.isIcon || props.isMulti || state.hasSubtext ? {
          components: {
            SingleValue,
            Option,
          },
        } : {})}
        {...(props.components ? { components: props.components } : {})}
        isDisabled={props.disabled}
        isMulti={props.isMulti}
        isLoading={props.isLoading}
        onFocus={state.onMenuOpen}
        onBlur={state.onMenuClose}
        hideSelectedOptions={false}
        closeMenuOnSelect={!props.isMulti}
        className={clsx('react-select', { [classes.disabled]: props.disabled })}
        defaultMenuIsOpen={props.defaultMenuIsOpen}
        closeMenuOnScroll={props.closeMenuOnScroll}
        menuPortalTarget={props.menuPortalTarget}
        menuIsOpen={props.menuIsOpen}
        onMenuOpen={props.onMenuOpen}
        onMenuClose={props.onMenuClose}
        isSearchable={props.isSearchable}
        filterOption={props.filterOption ? props.filterOption : state.filterOption}
      />
      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  )
}

export default Template
