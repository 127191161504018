import { useState, useEffect } from 'react';
import Typography from '../../components/Typography';
import ButtonField from '../../components/Button';
import TextField from '../../components/TextField';
import Tooltip from '../../components/Tooltip';
import copy from '../../assets/copy.svg';
import alertInfo from '../../assets/alert-info.svg';
import { EmailModal } from './EmailModal';

import {
    AlertMock,
    TypographyEl
  } from './NewPayment.style';

import { useNavigate } from 'react-router-dom';

const Icon = ({onClick}) => <svg style={{ cursor: 'pointer'}} onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M2.55806 2.55806C2.80214 2.31398 3.19786 2.31398 3.44194 2.55806L8 7.11612L12.5581 2.55806C12.8021 2.31398 13.1979 2.31398 13.4419 2.55806C13.686 2.80214 13.686 3.19786 13.4419 3.44194L8.88388 8L13.4419 12.5581C13.686 12.8021 13.686 13.1979 13.4419 13.4419C13.1979 13.686 12.8021 13.686 12.5581 13.4419L8 8.88388L3.44194 13.4419C3.19786 13.686 2.80214 13.686 2.55806 13.4419C2.31398 13.1979 2.31398 12.8021 2.55806 12.5581L7.11612 8L2.55806 3.44194C2.31398 3.19786 2.31398 2.80214 2.55806 2.55806Z" fill="white"/>
</svg>

export const Underpaid = () => {
    const [emailModalIsOpen, toggleModalEmail] = useState(false)
    const [tooltipIsOpen, toggleTooltip] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
      window.scrollTo(0, 0);
    },[])

    const onActionButtonClick = () => toggleModalEmail(true)

    return (
        <>
        <Typography style={{marginBottom: 4}} variant='h1' color='#040517'>Payment failed</Typography>
        <Typography style={{marginBottom: 24, paddingBottom: 24, borderBottom: '1px solid rgba(4, 5, 23, 0.08)'}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>Payment insufficient. Please create a new request</Typography>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 24}}>
            <TextField size='medium' style={{width: 220, height: 56, marginRight: 16}} disabled label="Amount" defaultValue="1357.53 USDT"/>
            <TextField style={{width: 220, height: 56}} disabled label="You paid" defaultValue="1207.53 USDT"/>
          </div>
          <TextField fullWidth style={{height: 56}} disabled label="Address" defaultValue="TR3t6a3oK5xK5N8b4K1Jv2L3pW4ZmU4sP3"/>
        <>
        <AlertMock>
        <Typography variant='caption2' color='rgba(20, 75, 255, 1)'>You haven't paid the remaining 150 USDT. We’ve sent the refund instructions to <span style={{fontWeight: 600}}>user@mail.com</span></Typography>
        <img src={alertInfo} alt='icon'/>
        </AlertMock>
        <Tooltip icon={<Icon onClick={() => toggleTooltip(false)}/>}
            text='Check the example to see what your customers will receive by email'
            placement='bottom'
            open={tooltipIsOpen}
            title='How does this email look?'
            actionButtonText='Preview'
            onActionButtonClick={onActionButtonClick}
            PopperProps={{style:{zIndex:0}}}
        >
        </Tooltip>
        </>
        <div>
          <Typography style={{marginBottom: 4, marginTop: 24,}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>TXID</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <TypographyEl>
            <Typography style={{marginBottom: 24, width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block'}} variant='subtitle1' color='#040517'>
              4428945e6bc7246ca46d1e7f3e89f0136682e7e357bd3c3a9вв27474531c0b63
            </Typography>
            </TypographyEl>
            <img style={{marginLeft: 4, marginBottom: 24}} src={copy} alt="icon"/>
            </div>
        </div>
        <ButtonField style={{marginBottom: 16}} size='large' onClick={() => navigate('/', { state: { location: '/payment-underpaid' }})}>Return to merchant</ButtonField>
        <EmailModal isUnderpayment={true} emailModalIsOpen={emailModalIsOpen} closeModalEmail={() => toggleModalEmail(false)} />
        </>
        
    )
}