import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {format} from 'date-fns';
import Typography from '../../components/Typography';
import TextField from '../../components/TextField';
import Checkbox from '../../components/Checkbox';
import ButtonField from '../../components/Button';
import alertWarning from '../../assets/alert-warning.svg';
import { AlertEmail } from './Refund.style';
import spiner from '../../assets/spinner.svg';
import copy from '../../assets/copy.svg';
import {
    CheckboxContainerMock,
    Spinner,
    TypographyEl
  } from './NewPayment.style';

export const Refund = () => {
    const [checked, handleChange] = useState(false)
    const [refundInProgress, handleRefundInProgress] = useState(false)
    const [refundCompleted, setRefundComleted] = useState(false)
    const [isUnderpayment, setIsUnderpayment] = useState(false)
    const [USDTAddressValue, setUSDTAddressValue] = useState('TKoa4zjYaZATN5THv6B36MhuCWKuLbh1en')
    const timer = useRef()
    const location = useLocation()

    const currentDate = () => format(new Date(), 'MMMM d, yyyy hh:mm a');

    const startTimer = (e) => {
      e.preventDefault();
      handleRefundInProgress(true)
      timer.current = setTimeout(() => {
        handleRefundInProgress(false)
        setRefundComleted(true)
      }, 10000)
    }

    useEffect(() => {
      if(location.state && location.state.from && location.state.from === '/payment-underpaid') {
        setIsUnderpayment(true)
      }
      if(location.pathname = '/refund') {
        handleRefundInProgress(false)
        setRefundComleted(false)
        handleChange(false)
        timer && timer.current && clearTimeout(timer.current)
        setUSDTAddressValue('TKoa4zjYaZATN5THv6B36MhuCWKuLbh1en')
      }
    }, [location])

    useEffect(() => {
      if(refundCompleted){
        clearTimeout(timer.current)
      }
    },[refundCompleted])
    
    useEffect(() => {
      window.scrollTo(0, 0);
      return () => {
          clearTimeout(timer.current)
          window.history.replaceState({}, '')
          handleRefundInProgress(false);
          setRefundComleted(false)
          setIsUnderpayment(false)
        }
    }, [])

    const amount = isUnderpayment ? '1207.53 USDT' : '150 USDT';
    const reason = isUnderpayment ? 'Your payment is less than the required total' : 'Payment exceeds the required amount';

    const getRefundDescription = () => {
      let message = `Created: ${currentDate()}`
      if(refundCompleted) {
        message = 'Your refund has been sent successfully.'
      }
      else if (refundInProgress) {
        message = reason
      }
      return message;
    }
    
    return (
    <>
    <Typography style={{marginBottom: 4}} variant='h1' color='#040517'>
      {refundInProgress ? 
      'Refund in progress' : refundCompleted ? 'Refund completed' : 'Refund request'}
    </Typography>
    <Typography 
        style={{paddingBottom: 24, marginBottom: 24, display: 'block', borderBottom: '1px solid rgba(4, 5, 23, 0.08)'}} 
        variant='caption3' 
        color='rgba(4, 5, 23, 0.56)'>
        {getRefundDescription()}
    </Typography>
    <TextField style={{marginBottom: 24}} disabled label="Refundable amount" value={amount}/>
    {
      refundInProgress || refundCompleted ? (
      <>
        <TextField style={{marginBottom: 24}} disabled label="Name" defaultValue="JumpSky Certificate #191"/>
        <TextField style={{marginBottom: 24}} disabled label="Invoice ID" defaultValue="211858"/>
      </>
      ) : (
        <TextField style={{marginBottom: 24}} disabled label="Reason" value={reason}/>
      )
    }
    <TextField 
      style={{marginBottom: refundInProgress ? 24: 8}} 
      disabled={refundInProgress || refundCompleted} 
      onChange={(event) => setUSDTAddressValue(event.target.value)} 
      label="USDT address"
      value={refundInProgress || refundCompleted ? 'TKoa4zjYaZATN5THv6B36MhuCWKuLbh1en' : USDTAddressValue}
    />
    
    {(!refundInProgress && !refundCompleted) &&
      <>
        <Typography style={{marginTop: 16, marginBottom: 24}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>A refund processing fee will be applied to the refundable amount</Typography>
        <AlertEmail>
          <Typography variant='caption2' color='#FF7A05'>Please provide a <span style={{fontWeight: 600}}>USDT (TRC20) wallet address where you wish to receive the refund</span></Typography>
          <img src={alertWarning} alt='icon' />
        </AlertEmail>
        <CheckboxContainerMock style={{marginBottom: 11}}>
          <Checkbox
            required
            checked={checked}
            onChange={(e) => handleChange(e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography variant='caption3' color='#040517'>I agree to the 
          <a style={{ textDecoration: 'none', color: '#144BFF' }} href="https://coinspaid.com/terms-of-use/" target='_blank'rel="noreferrer"> Terms of Use</a> and <a style={{textDecoration: 'none', color: '#144BFF'}} href="https://coinspaid.com/privacypolicy/" target='_blank' rel="noreferrer">Privacy Policy</a></Typography>
        </CheckboxContainerMock>
      </>
    }

    {refundInProgress &&
      <>
      <ButtonField size='large' startIcon={<Spinner src={spiner} alt="icon"/>} disabled={true}>Waiting for confirmation</ButtonField>
      <Typography style={{marginTop: 24, textAlign: 'center'}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>We will send updates about refund request to <span style={{fontWeight: 500, color: '#040517'}}>user@mail.com</span></Typography>
      </>
    }
    { refundCompleted &&
    <>
      <Typography style={{marginBottom: 4, marginTop: 24,}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>TXID</Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <TypographyEl>
        <Typography style={{
          marginBottom: 16, 
          width: '100%', 
          overflow: 'hidden', 
          textOverflow: 'ellipsis', 
          display: 'inline-block', 
        }}
          variant='subtitle1' color='#040517'>5398a264957952f62a611599321af54dfb2076d135d0e9f33768ba0df1a21ec0
          </Typography>
        </TypographyEl>
        <img style={{marginLeft: 16, marginBottom: 20}} src={copy} alt="icon"/>
      </div>
      </>
    }
    { (!refundCompleted && !refundInProgress) &&
      <>
      <ButtonField size='large' disabled={!checked} onClick={startTimer}>Get refund</ButtonField>
      <Typography style={{marginTop: 24, textAlign: 'center'}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>You will receive a refund within 48 hours</Typography>
      </>
    }
  </>
)}
