import tether from '../../assets/tether.svg';
import cardano from '../../assets/cardano.svg';
import bitcoinCash from '../../assets/bitcoin-cash.svg';
import binance from '../../assets/binance.svg';
import bnb from '../../assets/bnb.svg';
import ripple from '../../assets/ripple.svg';
import dai from '../../assets/dai.svg';
import bitcoin from '../../assets/bitcoin.svg';
import doge from '../../assets/doge.svg';
import eth from '../../assets/eth.svg';
import usdc from '../../assets/usdc.svg';
import eurs from '../../assets/eurs.svg';
import litecoin from '../../assets/ltc.svg';
import tron from '../../assets/trx.svg';
import usdtb from '../../assets/usdtb.svg';
import usdte from '../../assets/usdte.svg';
import wbtc from '../../assets/wbtc.svg';

const Icon = ({src}) => {
    return <img src={src} alt='coin-logo'/>
}
export const currency = [
    {value: 1, label: 'Tether USD (TRC20)', icon: <Icon src={tether}/>}, 
    {value: 2, label: 'Cardano', isDisabled: true, icon: <Icon src={cardano}/>},
    {value: 3, label: 'Bitcoin Cash', isDisabled: true, icon: <Icon src={bitcoinCash}/>},
    {value: 4, label: 'Binance Coin', isDisabled: true, icon: <Icon src={binance}/>},
    {value: 5, label: 'BNB', isDisabled: true, icon: <Icon src={bnb}/>},
    {value: 6, label: 'Bitcoin', isDisabled: true, icon: <Icon src={bitcoin}/>},
    {value: 7, label: 'Dai Stablecoin', isDisabled: true, icon: <Icon src={dai}/>},
    {value: 8, label: 'Dogecoin', isDisabled: true, icon: <Icon src={doge}/>},
    {value: 9, label: 'Ethereum', isDisabled: true, icon: <Icon src={eth}/>},
    {value: 10, label: 'STASIS EURS', isDisabled: true, icon: <Icon src={eurs}/>},
    {value: 11, label: 'Litecoin', isDisabled: true, icon: <Icon src={litecoin}/>},
    {value: 12, label: 'Tron', isDisabled: true, icon: <Icon src={tron}/>},
    {value: 13, label: 'USD Coin', isDisabled: true, icon: <Icon src={usdc}/>},
    {value: 14, label: 'Tether USD (BEP-20)', isDisabled: true, icon: <Icon src={usdtb}/>},
    {value: 15, label: 'Tether USD (ERC-20)', isDisabled: true, icon: <Icon src={usdte}/>},
    {value: 16, label: 'Wrapped BTC', isDisabled: true, icon: <Icon src={wbtc}/>},
    {value: 17, label: 'Ripple', isDisabled: true, icon: <Icon src={ripple}/>},
]