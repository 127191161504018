export const MuiOutlinedInput = {
  styleOverrides: {
    root: ({
      theme: {
        palette: { mode, ...palette },
      },
    }) => ({
      fontSize: '14px',
      fontWeight: 400,
      ...(mode === 'light' ? { background: palette.basic['A900'], color: palette.basic['900'] } : {}),

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.basic[300],
      },

      '&:hover:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.primary.main,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${palette.primary.main}`,
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        border: `1px dashed ${palette.basic[300]}`,
      },
      '&::placeholder': {
        color: palette.basic[800],
      },
    }),
    input: ({
      theme: {
        palette: { mode, ...palette },
      },
    }) => ({
      '&::placeholder': {
        ...(mode === 'light'
          ? {
            fill: palette.basic[800],
          }
          : {}),
        opacity: 1,
      },
    }),
  },
}
