import { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom';
import { 
  LandingButton,
  LandingTypographyH1,
  LandingTypographyH2,
  LandingTypographyH3,
  LandingTypographyH4, 
  HeroContainer, 
  HeroContainerInner, 
  AboutContainer, 
  LandingTypographyP, 
  AboutContainerInner,
  AboutContainerImages,
  AboutContainerInfo,
  PricingContainer,
  PricingContainerText,
  PricingContainerImages,
  FooterContainer,
  HeroLogo,
  HeroLogoMobile,
  AboutImage1,
  AboutImage2,
  AboutImage3,
  AboutContainerInfoItem,
  PricingImage1,
  PricingImage2,
  PricingImage3,
  PricingImage4,
  LandingFooter,
  CoinsPaidLogo,
  PricingContainerInner
} from './Landing.style'
import hero from '../../assets/hero.png';
import heroMobile from '../../assets/hero-mobile.png';
import jumpskyLogo from '../../assets/jumpsky-logo.svg';
import image1 from '../../assets/01.png';
import image2 from '../../assets/02.png';
import image3 from '../../assets/03.png';
import image4 from '../../assets/04.png';
import image5 from '../../assets/05.png';
import image6 from '../../assets/06.png';
import image7 from '../../assets/07.png';
import coinnLogo from '../../assets/coin-logo.svg';
import { DepositModal } from './components/modal/DepositModal';

export const Landing = () => {
  const [popupIsOpen, openPopup] = useState(false);
  const [isSuccessPayment, setIsSuccessPayment] = useState(false);
  const [isOverpaidPayment, setIsOverpaidPayment] = useState(false);
  const [isUnderpaidPayment, setIsUnderpaidPayment] = useState(false);
  const location = useLocation()

  useEffect(() => {
    if(location.state && location.state.location && location.state.location === '/payment-success') {
      setIsSuccessPayment(true);
      setIsOverpaidPayment(false);
      setIsUnderpaidPayment(false);
      openPopup(true);
    }

    if(location.state && location.state.location && location.state.location === '/payment-overpaid') {
      setIsSuccessPayment(false);
      setIsOverpaidPayment(true);
      setIsUnderpaidPayment(false);
      openPopup(true);
    }
    if(location.state && location.state.location && location.state.location === '/payment-underpaid') {
      setIsSuccessPayment(false);
      setIsOverpaidPayment(false);
      setIsUnderpaidPayment(true);
      openPopup(true);
    }
  }, [location])

const buyCertificate = () => {
  openPopup(true)
  setIsSuccessPayment(false)
  setIsOverpaidPayment(false)
  setIsUnderpaidPayment(false)
};

  return (
    <>
      <HeroContainer disableGutters={true} maxWidth={false}>
        <picture style={{maxHeight: 1080}}>
          <source media="(max-width: 799px)" srcSet={heroMobile} />
          <source media="(min-width: 800px)" srcSet={hero} />
          <img className='hero-logo' style={{maxHeight: 1080, height: '100%', width: '100%'}} src={hero} alt="Hero" />
        </picture>
        <HeroLogoMobile src={jumpskyLogo} alt="Logo" />
        <HeroContainerInner disableGutters={true} maxWidth={'sm'}>
          <HeroLogo src={jumpskyLogo} alt="Logo" />
          <LandingTypographyH3>
            Get  you first jump for just €1250!
          </LandingTypographyH3>
          <LandingTypographyH1>
            TANDEM<br/>
            SKYDIVING<br/>
            IN EUROPE
          </LandingTypographyH1>
          <LandingButton onClick={buyCertificate}>BUY CERTIFICATE</LandingButton>
        </HeroContainerInner>
      </HeroContainer>
      <AboutContainer disableGutters={true} maxWidth={false}>
        <LandingTypographyH4 secondary>
            About us
        </LandingTypographyH4>
        <AboutContainerInner disableGutters={true}>
          <LandingTypographyH2>
              WE  ARE
            <span style={{ color: '#1968B3'}}>  SKYDIVING  ENTHUSIASTS</span>
          </LandingTypographyH2>
          <LandingTypographyP style={{marginTop: 24, maxWidth: 620}}>
            JumpSky offers thrilling skydiving and base jumping experiences. Feel the rush of adrenaline, breathtaking views, and unparalleled freedom while safely soaring through the sky.
          </LandingTypographyP>
          <AboutContainerImages disableGutters={true}>
            <AboutImage1 src={image1} alt="img" />
            <AboutImage2 src={image2} alt="img" />
            <AboutImage3 src={image3} alt="img" />
          </AboutContainerImages>
          <AboutContainerInfo disableGutters={true} maxWidth={false}>
            <AboutContainerInfoItem>
              <LandingTypographyH2 secondary>
                5
              </LandingTypographyH2>
              <LandingTypographyH3 secondary>
              Offices in different countries.
              </LandingTypographyH3>
            </AboutContainerInfoItem>
            <AboutContainerInfoItem>
              <LandingTypographyH2 secondary>
                25
              </LandingTypographyH2>
              <LandingTypographyH3 secondary>
              Licensed professional instructors.
              </LandingTypographyH3>
            </AboutContainerInfoItem>
            <AboutContainerInfoItem>
              <LandingTypographyH2 secondary>
              1200+
              </LandingTypographyH2>
              <LandingTypographyH3 secondary>
              Successful dives with unique emotions!
              </LandingTypographyH3>
            </AboutContainerInfoItem>
          </AboutContainerInfo>
        </AboutContainerInner>
      </AboutContainer>
      <PricingContainer disableGutters={true} maxWidth={false}>
        <PricingContainerInner>
          <PricingContainerImages disableGutters={true}>
            <PricingImage1 src={image4} alt="img" />
            <PricingImage2 src={image5} alt="img" />
          </PricingContainerImages>
          <PricingContainerText>
            <LandingTypographyH4>
                Pricing
            </LandingTypographyH4>
            <LandingTypographyH2 tertiary>
                UNBELIEVABLE  EXPERIENCE&nbsp;
                <span style={{ color: '#FEDD67'}}>FOR JUST €1250!</span>
            </LandingTypographyH2>
            <LandingButton onClick={buyCertificate}>BUY CERTIFICATE</LandingButton>
          </PricingContainerText>
          <PricingContainerImages disableGutters={true}>
            <PricingImage3 src={image6} alt="img" />
            <PricingImage4 src={image7} alt="img" />
          </PricingContainerImages>
        </PricingContainerInner>
      </PricingContainer>
      <LandingFooter>
      <FooterContainer disableGutters={true}>
        <LandingTypographyH3>
          This is a demo site. 
          Any deposit made on this site will not be honoured or fulfilled.
        </LandingTypographyH3>
        <CoinsPaidLogo src={coinnLogo} alt="Logo"/>
        <LandingTypographyP secondary>© 2024 CoinsPaid. Start your future today</LandingTypographyP>
      </FooterContainer>
      </LandingFooter>
      {/* <DepositModal popupIsOpen={popupIsOpen} openPopup={openPopup}/> */}
      <DepositModal
      isSuccessPayment={isSuccessPayment}
      isOverpaidPayment={isOverpaidPayment}
      isUnderpaidPayment={isUnderpaidPayment}
      setIsSuccessPayment={setIsSuccessPayment}
      setIsOverpaidPayment={setIsOverpaidPayment}
      setIsUnderpaidPayment={setIsUnderpaidPayment}
      popupIsOpen={popupIsOpen} openPopup={openPopup} />
    </>
  )
}
