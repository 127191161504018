export const MuiAlert = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      borderRadius: theme.typography.pxToRem(8),
      flexDirection: 'row',
      alignItems: 'center',
      color: theme.palette.basic['A900'],
    }),
    icon: ({ theme }) => ({
      marginRight: theme.spacing(2),
      marginLeft: 0,
      alignItems: 'center',

      '& svg': {
        width: theme.typography.pxToRem(16),
        height: theme.typography.pxToRem(16),
      },
    }),
    filled: ({ theme }) => ({
      borderRadius: 0,
      '& .MuiAlert-icon': {
        '& svg path': {
          fill: theme.palette.basic['A900'],
        },
      },
    }),
    filledSuccess: ({ theme }) => ({
      backgroundColor: theme.palette.success.main,
    }),
    filledInfo: ({ theme }) => ({ backgroundColor: theme.palette.primary.main }),
    filledWarning: ({ theme }) => ({ backgroundColor: theme.palette.warning.main }),
    filledError: ({ theme }) => ({ backgroundColor: theme.palette.error.main }),
    standardWarning: ({ theme }) => ({
      color: theme.palette.warning.main,
      '&.MuiAlertTitle-root svg path': {
        fill: theme.palette.warning.main,
      },
    }),
    standardError: ({ theme }) => ({
      color: theme.palette.error.main,
      '&.MuiAlertTitle-root svg path': {
        fill: theme.palette.error.main,
      },
    }),
    standardSuccess: ({ theme }) => ({
      color: theme.palette.success.main,
      '&.MuiAlertTitle-root svg path': {
        fill: theme.palette.success.main,
      },
    }),
    standardInfo: ({ theme }) => ({
      color: theme.palette.primary.main,
      '&.MuiAlertTitle-root svg path': {
        fill: theme.palette.primary.main,
      },
    }),
    action: {
      paddingTop: 0,
    },
    message: {
      flex: '1',
    },
  },
}
