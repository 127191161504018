import { pxToRem } from '../../../utils/pxToRem'

export const typography = {
  fontFamily: 'Inter, sans-serif',

  h1: {
    fontWeight: 700,
    fontSize: pxToRem(32),
    lineHeight: pxToRem(40),
  },
  h2: {
    fontWeight: 600,
    fontSize: pxToRem(26),
    lineHeight: pxToRem(30),
    letterSpacing: pxToRem(0.04),
  },
  h3: {
    fontWeight: 600,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(24),
    letterSpacing: pxToRem(0.08),
  },
  h4: {
    fontWeight: 600,
    fontSize: pxToRem(18),
    lineHeight: pxToRem(22),
    letterSpacing: pxToRem(0.12),
  },
  body1: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22),
    letterSpacing: pxToRem(0.08),
  },
  body2: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22),
    letterSpacing: pxToRem(0.08),
  },
  body3: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22),
    letterSpacing: pxToRem(0.08),
  },
  subtitle1: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(0.12),
  },
  subtitle2: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(0.12),
  },
  subtitle3: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(0.12),
  },

  caption1: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    letterSpacing: pxToRem(0.16),
  },
  caption2: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    letterSpacing: pxToRem(0.16),
  },
  caption3: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    letterSpacing: pxToRem(0.16),
  },
  overline: {
    fontWeight: 500,
    fontSize: pxToRem(10),
    lineHeight: pxToRem(12),
    textTransform: 'uppercase',
    letterSpacing: pxToRem(1.2),
  },
  small: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: pxToRem(10),
    lineHeight: pxToRem(12),
    letterSpacing: pxToRem(0.12),
  },
}
