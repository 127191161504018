import { useState, useRef, useEffect } from 'react';
import {format} from 'date-fns';
import Typography from '../../components/Typography';
import ButtonField from '../../components/Button';
import TextField from '../../components/TextField';
import Tooltip from '../../components/Tooltip';
import qrMock from '../../assets/qr-mock.png';
import info from '../../assets/info.svg';
import alert from '../../assets/alert.svg';
import copy from '../../assets/copy.svg';
import alertInfo from '../../assets/alert-info.svg';
import spiner from '../../assets/spinner.svg';
import { millisToMinutesAndSeconds } from '../../utils/mlToSec';

import {
    Spinner,
    AlertMock,
    TypographyEl
  } from './NewPayment.style';
import { useNavigate } from 'react-router-dom';

export const PartialPayment = () => {
  const [timerCounter, setTimer] = useState(300000);
  const currentDate = () => format(new Date(), 'MMMM d, yyyy hh:mm a');
  const timerId = useRef()
  const timerCounterId = useRef()
  const navigate = useNavigate()

  const startTimer = () => {
    timerId.current = setTimeout(() => {
      navigate('/payment-underpaid')
    }, 300000) //5 min
  }

  const startTimerCounter = () => {
    timerCounterId.current = setInterval(() => {
      setTimer(timerValue => timerValue - 1000)
    }, 1000) //5 min - every sec
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    startTimer();
    startTimerCounter();
    return () => {
      clearTimeout(timerId.current)
      clearInterval(timerCounterId.current)
    }
  }, [])

  return (
    <>
      <div style={{display: 'flex', marginBottom: -26}}>
        <img style={{marginLeft: -26, marginTop: -8, width: 196, height: 196 }} src={qrMock} alt="qr"/>
        <div>
        <Typography style={{marginBottom: 4}} variant='h1' color='#040517'>New payment</Typography>
        <Typography style={{marginBottom: 8}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>Created: {currentDate()}</Typography>
        </div>
      </div>
      <div style={{marginBottom: 24, paddingBottom: 24, borderBottom: '1px solid rgba(4, 5, 23, 0.08)'}}>
        <div style={{display: 'flex', flexShrink: 0, marginBottom: 8}}>
          <Typography style={{minWidth: 123, display: 'inline-flex'}} variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Description:</Typography>
          <Typography variant='subtitle3' color='#040517'>A certificate granting one parachute jump</Typography>
        </div>
        <div style={{marginBottom: 8}}>
          <Typography style={{width: 123, display: 'inline-block'}} variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Time remaining:</Typography>
          <div style={{display: 'inline-flex', alignItems: 'center'}}>
            <Typography style={{ display: 'inline-flex', alignItems: 'center', width: 39}} variant='subtitle3' color='#FF4754'>
              {millisToMinutesAndSeconds(timerCounter)}
            </Typography>
            <div>
              <Tooltip
                placement='top'
                title='When the timer runs out, the payment request will expire'
              >
                <img style={{marginLeft: 4, marginTop: 3}} src={info} alt="icon"/>
            </Tooltip>
          </div>
          </div>
        </div>
        <div style={{marginBottom: 8}}>
          <Typography style={{width: 123, display: 'inline-block'}} variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Amount:</Typography>
          <Typography style={{}} variant='subtitle3' color='#040517'>1250 EUR</Typography>
        </div>
        <div style={{marginBottom: 8}}>
          <Typography style={{width: 123, display: 'inline-block'}} variant='subtitle3' color='rgba(4, 5, 23, 0.56)'>Min deposit:</Typography>
          <Typography style={{}} variant='subtitle3' color='#040517'>5 USDT</Typography>
        </div>
      </div>
      <div style={{marginBottom: 24, display: 'flex',padding: 16, flexDirection: 'column', borderRadius: 8,background: 'rgba(4, 5, 23, 0.04)'}}>
        <Typography style={{marginBottom: 8, display: 'flex', alignItems: 'center'}} variant='subtitle1' color='#FF7A05'><img style={{marginRight: 4}} src={alert} alt="icon"/>Pay attention!</Typography>
        <Typography style={{marginBottom: 4, display: 'flex'}} variant='caption3' color='rgba(4, 5, 23, 0.56)'><span>&bull;</span><span style={{paddingLeft: 8}}>Send only <span style={{fontWeight: 600}}>USDT using TRC-20</span> standard. Sending any other currency or using other standard may result in loss of funds</span></Typography>
        <Typography style={{display: 'flex'}} variant='caption3' color='rgba(4, 5, 23, 0.56)'><span>&bull;</span><span style={{paddingLeft: 8}}>If the wallet you are using <span style={{fontWeight: 600}}>charges a fee</span> that reduces the total amount that is sent, please send enough to cover it</span></Typography>
      </div>
      <div>
        <Typography style={{marginBottom: 8}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>Amount to pay</Typography>
        <Typography style={{marginBottom: 24, fontSize: 24}} variant='body3' color='#040517'>1357.53 USDT<img style={{marginLeft: 4}} src={copy} alt="icon"/></Typography>
      </div>
      <TextField readOnly style={{marginBottom: 24}} label="USDT address to pay" defaultValue="TR3t6a3oK5xK5N8b4K1Jv2L3pW4ZmU4sP3"/>
      <AlertMock style={{marginTop: 0, justifyContent: 'space-between'}}>
        <Typography variant='caption2' color='rgba(20, 75, 255, 1)'>You only paid 1207.53 USDT. Please pay the remaining 150 USDT</Typography>
        <img src={alertInfo} alt='icon'/>
      </AlertMock>
      <div>
          <Typography style={{marginBottom: 4, marginTop: 24,}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>TXID</Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 24}}>
          <TypographyEl>
            <Typography style={{
                width: '100%', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                display: 'inline-block', 
              }}
              variant='subtitle1' color='#040517'>4428945e6bc7246ca46d1e7f3e89f0136682e7e357bd3c3a9вв27474531c0b63</Typography>
            </TypographyEl>
            <img style={{marginLeft: 16, marginBottom: 4}} src={copy} alt="icon"/>
            </div>
        </div>
        <ButtonField size='large' startIcon={<Spinner src={spiner} alt="icon"/>} disabled={true}>Waiting for payment</ButtonField>
        <Typography style={{marginTop: 24, textAlign: 'center'}} variant='caption3' color='rgba(4, 5, 23, 0.56)'>
          You agree to the <a style={{ textDecoration: 'none', color: '#144BFF' }} href="https://coinspaid.com/terms-of-use/" target='_blank'rel="noreferrer"> Terms of Use</a> and <a style={{textDecoration: 'none', color: '#144BFF'}} href="https://coinspaid.com/privacypolicy/" target='_blank' rel="noreferrer">Privacy Policy</a>.
          <br/>All payment information will be sent to <span style={{ color: '#040517', fontWeight: 500 }}>user@mail.com</span></Typography>
    </>
  )
}
