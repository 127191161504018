import { alpha } from '@mui/system'

const colorVariants = ['inherit', 'primary', 'secondary', 'error', 'warning', 'info', 'success']

const getAlphaColor = (color, palette, opacity) => {
  if (color !== 'inherit' && palette[color]?.main) {
    return alpha(palette[color].main, opacity)
  }

  return alpha(palette.basic[100], opacity)
}

export const MuiButton = {
  variants: [
    ...colorVariants.map((color) => ({
      props: { color: color, variant: 'contained' },
      style: ({ theme: { palette } }) => ({
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: palette[color]?.main,
            filter: 'saturate(104%) brightness(92%)',
          },
        },
        '&.Mui-disabled': {
          color: palette.default.main,
          backgroundColor: getAlphaColor(color, palette, palette.action.disabledOpacity),
        },
      }),
    })),
    ...colorVariants.map((color) => ({
      props: { color: color, variant: 'outlined' },
      style: ({ theme: { palette } }) => ({
        borderColor: palette.basic[200],
        '@media (hover: hover)': {
          '&:hover': {
            borderColor:
              color === 'secondary'
                ? palette.basic[300]
                : getAlphaColor(color, palette, palette.action.disabledOpacity),
            backgroundColor: 'transparent',
          },
        },
        '@media (hover: none)': {
          '&:hover': {
            borderColor:
              color === 'secondary'
                ? palette.basic[300]
                : getAlphaColor(color, palette, palette.action.disabledOpacity),
          },
        },

        '&:active': {
          borderColor:
            color === 'secondary' ? palette.basic[300] : getAlphaColor(color, palette, palette.action.disabledOpacity),
          backgroundColor:
            color === 'secondary' ? palette.basic[100] : getAlphaColor(color, palette, palette.action.focusOpacity),
        },

        '&.Mui-disabled': {
          borderColor: palette.basic[200],
          color: palette.basic[500],
        },
      }),
    })),
    ...colorVariants.map((color) => ({
      props: { color: color, variant: 'text' },
      style: ({ theme: { palette } }) => ({
        '& svg path': {
          fill: palette[color]?.main,
        },
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: 'transparent',
            filter: 'saturate(104%) brightness(92%)',
          },
        },
        '&.Mui-disabled': {
          color: palette.basic[500],
        },
      }),
    })),
    {
      props: { color: 'secondary', variant: 'text' },
      style: ({ theme: { palette } }) => ({
        color: palette.basic[700],
        '& svg path': {
          fill: palette.basic[700],
        },

        '@media (hover: hover)': {
          '&:hover': {
            color: palette.basic[800],
            '& svg path': {
              fill: palette.basic[800],
            },
          },
        },
      }),
    },
  ],
}
