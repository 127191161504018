import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { Button as ButtonUI } from '@mui/material'
import styles from './styles'

const ButtonField = forwardRef((props, ref) => {
  const {
    name,
    size,
    color,
    startIcon,
    endIcon,
    variant,
    disabled,
    disableElevation,
    disableFocusRipple,
    disableRipple,
    href,
    className,
    onClick,
    fullWidth,
    type,
    dataTestId,
    sx,
    component,
    ...rest
  } = props

  const classes = styles()

  return (
    <ButtonUI
      {...rest}
      type={type}
      name={name}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      disabled={disabled}
      disableElevation={disableElevation}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      href={href}
      ref={ref}
      onClick={onClick}
      fullWidth={fullWidth}
      className={className}
      classes={classes}
      data-testid={`button-${dataTestId}`}
      sx={sx}
      component={component}
    >
      {props.children}
    </ButtonUI>
  )
})

ButtonField.propTypes = {
  /**
   * The name of the button.
   */
  name: PropTypes.string,
  /**
   * Use full width of parent element.
   */
  fullWidth: PropTypes.bool,
  /**
   * The content of the button.
   */
  children: PropTypes.any,
  /**
   * Color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOfType([PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'warning',
    'error',
    'info',
    'inherit',
  ]), PropTypes.string]),
  /**
   * The size of the button.
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * The variant to use.
   */
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  /**
   * Href attribute for the button.
   */
  href: PropTypes.string,
  /**
   * Icon placed before the children.
   */
  startIcon: PropTypes.element,
  /**
   * Icon placed after the children.
   */
  endIcon: PropTypes.element,
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  className: PropTypes.string,
  /**
   * Disable state of the button.
   */
  disabled: PropTypes.bool,
  /**
   * Disable elevation
   */
  disableElevation: PropTypes.bool,
  /**
   * Disable focus ripple
   */
  disableFocusRipple: PropTypes.bool,
  /**
   * Disable ripple
   */
  disableRipple: PropTypes.bool,
  /**
   * Handle click event
   */
  onClick: PropTypes.func,
}

ButtonField.defaultProps = {
  variant: 'contained',
  size: 'medium',
  disableRipple: true,
  disableFocusRipple: true,
}

export default ButtonField
