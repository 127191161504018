export const UiTooltip = {
  styleOverrides: {
    root: ({ theme: { palette, spacing, typography } }) => ({
      '& .MuiTooltip-arrow': {
        color: palette.surface.accent,
        width: typography.pxToRem(13),
        height: typography.pxToRem(10),

        '&:before': {
          borderRadius: '1px',
        },
      },

      '& .MuiTooltip-tooltip': {
        color: palette.static['900'],
        backgroundColor: palette.surface.accent,
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        paddingLeft: spacing(1.25),
        paddingRight: spacing(1.25),
        borderRadius: spacing(1),
        fontWeight: 500,
        fontSize: typography.pxToRem(12),
        lineHeight: typography.pxToRem(16),
        maxWidth: 'none',
      },

      '& svg path': {
        fill: palette.static['900'],
      }
    }),
  },
}
