import React from 'react'
import PropTypes from 'prop-types'
import TypographyUi from '@mui/material/Typography'

/**
 * Returns MUI Typography. Use typography to present your design and content as clearly and efficiently as possible.
 *
 * @param props
 * @param {string} props.align - Set the text-align on the component
 *
 * @param {ReactElement} props.children - The content of the component
 *
 * @param {string} props.className - The component used for the root node
 *
 * @param {ReactElement} props.component - If `true`, the text will have a bottom margin
 *
 * @param {bool} props.gutterBottom - If `true`, the text will not wrap,
 * but instead will truncate with a text overflow ellipsis
 *
 * @param {bool} props.noWrap - If `true`, the text will not wrap, but
 * instead will truncate with a text overflow ellipsis
 *
 * @param {bool} props.paragraph - If `true`, the text will have a bottom margin
 *
 * @param {object} props.sx - The system prop that allows defining system overrides as well as additional CSS styles
 *
 * @param {string} props.variant - Applies the theme typography styles
 *
 * @param {object} props.variantMapping - The component maps the variant prop to a range of different HTML element types
 * */

const Typography = (props) => {
  const {
    align,
    children,
    color,
    display,
    gutterBottom,
    noWrap,
    paragraph,
    variant,
    variantMapping,
    className,
    dataTestId,
    sx,
    component,
    ...defaultProps
  } = props

  return (
    <TypographyUi
      align={align}
      color={color}
      display={display}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      paragraph={paragraph}
      variant={variant}
      variantMapping={variantMapping}
      className={className}
      sx={sx}
      component={component}
      {...(dataTestId ? { 'data-testid': `typography-${dataTestId}` } : {})}
      {...defaultProps}
    >
      {children}
    </TypographyUi>
  )
}

Typography.propTypes = {
  /**
   * Set the text-align on the component.
   * @default 'inherit'
   */
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the text will have a bottom margin.
   * @default false
   */
  gutterBottom: PropTypes.bool,
  /**
   * If `true`, the text will not wrap, but instead will truncate with a text overflow ellipsis.
   *
   * Note that text overflow can only happen with block or inline-block level elements
   * (the element needs to have a width in order to overflow).
   * @default false
   */
  noWrap: PropTypes.bool,
  /**
   * If `true`, the text will have a bottom margin.
   * @default false
   */
  paragraph: PropTypes.bool,
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.object,
  /**
   * Applies the theme typography styles.
   * @default 'body1'
   */
  variant: PropTypes.oneOfType([
    PropTypes.oneOf([
      'body1',
      'body2',
      'body3',
      'captio1',
      'captio2',
      'captio3',
      'h1',
      'h2',
      'h3',
      'h4',
      'overline',
      'subtitle1',
      'subtitle2',
      'subtitle3',
    ]),
    PropTypes.string,
  ]),
  /**
   * The component maps the variant prop to a range of different HTML element types.
   * For instance, subtitle1 to `<h6>`.
   * If you wish to change that mapping, you can provide your own.
   * Alternatively, you can use the `component` prop.
   * @default {
   *   h1: 'h1',
   *   h2: 'h2',
   *   h3: 'h3',
   *   h4: 'h4',
   *   h5: 'h5',
   *   h6: 'h6',
   *   subtitle1: 'h6',
   *   subtitle2: 'h6',
   *   body1: 'p',
   *   body2: 'p',
   *   inherit: 'p',
   * }
   */
  variantMapping: PropTypes.object,
}

Typography.defaultProps = {
  align: 'left',
  gutterBottom: false,
  noWrap: false,
  paragraph: false,
  variant: 'body1',
  variantMapping: {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    subtitle1: 'span',
    subtitle2: 'span',
    subtitle3: 'span',
    body1: 'p',
    body2: 'p',
    body3: 'p',
    caption1: 'p',
    caption2: 'p',
    caption3: 'p',
    inherit: 'p',
  },
}

export default Typography
