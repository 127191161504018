import { useMemo, useState } from 'react'

import _isFunction from 'lodash/isFunction'
import _isString from 'lodash/isString'
import _map from 'lodash/map'
import PropTypes from 'prop-types'
import { renderToString } from 'react-dom/server'

import template from './template'
import template_async from './template_async'

const isContainsSubstring = (mainString, substring) => {
  if (!mainString) {
    return false
  }

  const lowerCaseMainString = mainString.toLowerCase()
  const lowerCaseSubstring = substring.toLowerCase()

  return lowerCaseMainString.includes(lowerCaseSubstring)
}

/**
 * Returns MUI Select. Used to select one or more options of set. Looks like dropdown.
 *
 * @param props
 * @param {string} props.status - e
 *
 * @param {string} props.error - e
 *
 * @param {string} props.className -  Override or extend the styles applied to the component
 * */
const Select = (props) => {
  const [isFocus, setIsFocus] = useState(false)
  const {
    isClearable,
    loadOptions,
    options: propsOptions,
  } = props

  const options = useMemo(
    () => {
      const optionList = isClearable && !_isFunction(loadOptions)
        ? [{
          // label: t('tables:filters.clear', 'Clear Filter'),
          label: 'Clear Filter',
          value: null,
          isClearable: true,
        }, ...propsOptions]
        : propsOptions

      // TO DO renderToString for serch by label
      return _map(optionList, (option) => {
        const label = _isString(option.label) ? option.label : renderToString(option.label)

        return {
          ...option,
          label,
        }
      })
    },
    [propsOptions],
  )

  const hasSubtext = options.some((option) => option.subtext)

  const filterOption = (option, searchText) => {
    const {
      label,
      data,
    } = option

    const labelMatch = isContainsSubstring(label, searchText)
    const subtextMatch = isContainsSubstring(data?.subtext, searchText)

    return labelMatch || subtextMatch
  }

  const state = {
    options,
    onMenuOpen,
    onMenuClose,
    isFocus,
    hasSubtext,
    filterOption,
  }

  function onMenuOpen() {
    setIsFocus(true)
  }
  function onMenuClose() {
    setIsFocus(false)
  }

  return !_isFunction(props.loadOptions)
    ? template(props, state)
    : template_async(props, state)
}

Select.propTypes = {
  /**
   * Custom classnames
   */
  className: PropTypes.string,
  /**
   * If `true`, close the select menu when the user scrolls the document/body.
   *
   * If a function, takes a standard javascript `ScrollEvent` you return a boolean:
   *
   * `true` => The menu closes
   *
   * `false` => The menu stays open
   *
   * This is useful when you have a scrollable modal and want to portal the menu out,
   * but want to avoid graphical issues.
   */
  closeMenuOnScroll: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /**
   * Array of components overriding MUI Select standard components
   */
  components: PropTypes.object,
  /**
   * Component to render custom option
   */
  customOption: PropTypes.func,
  /**
   * The defaultOptions prop determines "when" your
   * remote request is initially fired. ref: https://react-select.com/async#defaultoptions
   */
  defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  /**
   * If true the Select will be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Could be an error message or a flag that an error has occurred
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * If true, the width of the popover will automatically be set
   * according to the items inside the menu, otherwise it will be at least the width of the select input.
   */
  fullWidth: PropTypes.bool,
  /**
   * Handle the menu opening and closing
   */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * Used with isIcon to make extra padding
   */
  isAdornment: PropTypes.bool,
  /**
   * If true the Select field will render a button to reset selected options
   */
  isClearable: PropTypes.bool,
  /**
   * If true the Select will render icon
   */
  isIcon: PropTypes.bool,
  /**
   * If true value must be an array and the Select will support multiple selections.
   */
  isMulti: PropTypes.bool,
  /**
   *  Whether to enable search functionality
   */
  isSearchable: PropTypes.bool,
  /**
   * The label of the input. It is only used for layout.
   * The actual labelling is handled by InputLabel. If specified labelWidth is ignored.
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Callback to load options from the server
   */
  loadOptions: PropTypes.func,
  /**
   * Limiting the height of the dropdown
   */
  maxMenuHeight: PropTypes.number,
  /**
   *  Whether the menu is open
   */
  menuIsOpen: PropTypes.bool,
  /**
   * Whether the menu should use a portal, and where it should attach
   *
   * An example can be found in the [Portaling](/advanced#portaling) documentation
   */
  menuPortalTarget: PropTypes.any,
  /**
   *
   */
  multiValueRemoveComponent: PropTypes.func,
  /**
   * Callback function fired when a menu item is selected.
   *
   * Signature:
   * function(event: object, child?: object) => void
   * event: The event source of the callback. You can pull out the new value by accessing event.target.value (any).
   * child: The react element that was selected when native is false (default).
   */
  onChange: PropTypes.func,
  /**
   * Handle the click on select control
   */
  onClickControl: PropTypes.func,
  /**
   *  Handle the menu closing
   */
  onMenuClose: PropTypes.func,
  /**
   * Handle the menu opening
   */
  onMenuOpen: PropTypes.func,
  /**
   * The option elements to populate the select with. [{ value, label, icon }]
   */
  options: PropTypes.array,
  /**
   * No options message
   */
  noOptionsMessage: PropTypes.any,
  /**
   * Field status message
   */
  status: PropTypes.string,
}

export default Select
