import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material'
import { getTheme } from '../theme'

export function MockTheme({ children, mode = 'light' }) {
  const defaultTheme = getTheme('coinspaid')(mode)
  const theme = createTheme(defaultTheme)

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
