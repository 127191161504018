import { makeStyles } from '@mui/styles'
import { spacing } from '@mui/system'

export default makeStyles(
  (theme) => {
    const { typography, palette } = theme

    const iconSize = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '16px',
      height: '16px',
      ...spacing({ theme, mr: 0.5 }),
      '& > *:nth-of-type(1)': {
        fontSize: '14px',
      },
      padding: '1px',
      marginLeft: 0,
      boxSizing: 'content-box',

      '& svg': {
        width: '100%',
        height: '100%',
      },
    }

    return {
      root: {
        fontSize: typography.pxToRem(14),
        lineHeight: typography.pxToRem(20),
        minWidth: 'auto',
        textTransform: 'none',
      },
      label: {
        textTransform: 'initial',
        whiteSpace: 'nowrap',
      },
      sizeSmall: {
        ...spacing({ theme, px: 1, py: 0.5 }),
      },
      sizeMedium: {
        ...spacing({ theme, px: 1.5, py: 1.25 }),
      },
      sizeLarge: {
        ...spacing({ theme, px: 2.25, py: 1.75 }),
      },
      iconSizeSmall: iconSize,
      iconSizeMedium: iconSize,
      iconSizeLarge: iconSize,
      contained: {
        color: palette.basic['A900'],
        '&.Mui-disabled': {
          color: palette.basic['A900'],
        },
        '& svg path': {
          fill: palette.basic['A900'],
        },
        '& svg circle': {
          stroke: palette.basic['A900'],
        },
      },
      containedInherit: {
        color: palette.static['900'],
      },
      containedDefault: {
        color: 'inherit',
      },
      containedSecondary: {
        color: palette.static['900'],
        '&.Mui-disabled': {
          color: palette.static['400'],
        },
        '& svg path': {
          color: palette.static['900'],
        },
      },
      outlinedSecondary: {
        color: palette.basic[700],
        '& svg path': {
          fill: palette.basic[700],
        },
        '&.Mui-disabled': {
          '& svg path': {
            fill: palette.basic[400],
          },
        },
      },
      outlinedSizeMedium: {
        padding: `${typography.pxToRem(9)} ${typography.pxToRem(11)}`,
      },
      outlinedSizeLarge: {
        padding: `${typography.pxToRem(13)} ${typography.pxToRem(17)}`,
      },
    }
  },
  {
    name: 'MuiButton',
  }
)
