export default {
  mode: 'dark',
  primary: {
    main: 'rgba(130, 162, 250, 1)',
  },
  secondary: {
    main: 'rgba(26, 26, 29, 1)',
  },
  error: {
    main: 'rgba(248, 129, 129, 1)',
  },
  warning: {
    main: 'rgba(249, 185, 91, 1)',
  },
  success: {
    main: 'rgba(84, 209, 134, 1)',
  },
  info: {
    main: 'rgba(130, 162, 250, 1)',
  },
  background: {
    default: 'rgba(11, 11, 15, 1)', //body
    paper: 'rgba(18, 18, 22, 1)', //layout
    above: 'rgba(26, 26, 29, 1)',
    menu: 'rgba(18, 18, 22, 1)',
    tint: 'rgba(0, 0, 8, 0.68)',
  },
  surface: {
    accent: 'rgba(43, 43, 45, 1)',
  },
  default: {
    main: 'white', //Biutton color="white"
  },
  static: {
    900: 'rgba(255, 255, 255, 1)',
    800: 'rgba(255, 255, 255, 0.64)',
    700: 'rgba(255, 255, 255, 0.48)',
    600: 'rgba(255, 255, 255, 0.4)',
    500: 'rgba(255, 255, 255, 0.32)',
    400: 'rgba(255, 255, 255, 0.24)',
    300: 'rgba(255, 255, 255, 0.16)',
    200: 'rgba(255, 255, 255, 0.08)',
    100: 'rgba(255, 255, 255, 0.04)',
  },
  basic: {
    A900: 'rgba(18, 18, 22, 1)',
    A800: 'rgba(18, 18, 22, 0.68)',
    A700: 'rgba(18, 18, 22, 0.56)',
    A600: 'rgba(18, 18, 22, 0.40)',
    A500: 'rgba(18, 18, 22, 0.32)',
    A400: 'rgba(18, 18, 22, 0.24)',
    A300: 'rgba(18, 18, 22, 0.16)',
    A200: 'rgba(18, 18, 22, 0.08)',
    A100: 'rgba(18, 18, 22, 0.04)',
    100: 'rgba(255, 255, 255, 0.04)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.32)',
    600: 'rgba(255, 255, 255, 0.40)',
    700: 'rgba(255, 255, 255, 0.48)',
    800: 'rgba(255, 255, 255, 0.64)',
    900: 'rgba(255, 255, 255, 1)',
  },
  shadows: {
    regular: '0px 7px 20px rgba(0, 0, 0, 0.07)',
    large: '0px 7px 64px rgba(0, 0, 0, 0.07)',
    hover: '0px 8px 10px rgba(16, 20, 23, 0.04), 0px 8px 16px rgba(16, 20, 23, 0.16)',
    standart:
      '0px 0px 2px rgba(16, 20, 23, 0.04), 0px 1px 1px rgba(16, 20, 23, 0.02), 0px 1px 4px rgba(16, 20, 23, 0.08)',
  },
  action: {
    disabledOpacity: 0.32,
    focusOpacity: 0.1,
  },
}
