import * as React from 'react';
import { Route, Routes } from "react-router-dom";
import { Landing } from './pages/landing/Landing';
import { NewPayment } from './pages/payment/NewPayment';
import { TimeExpired } from './pages/time-expired/TimeExpired';
import { MockTheme } from './utils/MockTheme';
import { PaymentLayout } from './pages/payment-layout/PaymentLayout';
import { Refund } from './pages/payment/Refund';
import { Underpaid } from './pages/payment/Underpaid';
import { PartialPayment } from './pages/payment/PartialPayment';

function App() {
  return (
    <MockTheme>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route element={<PaymentLayout />}>
          <Route path="/new-payment" element={<NewPayment />} />
          <Route path="/payment-detected" element={<NewPayment />} />
          <Route path="/payment-success" element={<NewPayment />} />
          <Route path="/expired" element={<NewPayment />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/payment-overpaid" element={<NewPayment />} />
          <Route path="/payment-underpaid" element={<Underpaid />} />
          <Route path="/partial-payment" element={<PartialPayment />} />
        </Route>
      </Routes>
    </MockTheme>
  );
}

export default App;
