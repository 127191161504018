export const MuiInputBase = {
  styleOverrides: {
    root: {
      fontWeight: 400,
    },
    input: ({ theme: { palette } }) => ({
      '&::placeholder': {
        color: palette.basic[800],
      },
    }),
  },
}
