import styled from '@emotion/styled'

export const CheckboxContainerMock = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    marginLeft: -8,
    "@media (max-width: 450px)": {
    }
})

export const TypographyEl = styled('div')({
    width: 424,
    "@media (max-width: 450px)": {
        width: 280,
    }
})

export const Spinner = styled('img')({
    animation: 'spinner 1.2s linear infinite',
    "@keyframes spinner":  {
        "0%": {
          transform: 'rotate(0deg)',
        },
        "100%": {
          transform: 'rotate(360deg)',
        }
      },
    "@media (max-width: 450px)": {
    }
})

export const AlertMock = styled('div')({
  position: 'relative',
  backgroundColor: 'rgba(20, 75, 255, 0.10)',
  borderRadius: 8,
  padding: '12px 16px',
  marginTop: 24,
  display: 'flex',
  alignItems: 'center',
  "@media (max-width: 450px)": {
      // width: 280,
  }
})

export const PaymentExpiredContainer = styled('div')({
  marginTop: 16,
  "@media (max-width: 450px)": {
      marginTop: 0,
  }
})
